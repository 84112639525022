import * as React from 'react';
import { Skeleton } from '@mui/material';

// components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CardMedia from '@mui/material/CardMedia';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import img from './osoul-graphic-stock.webp'

import Page from '../../components/Page';
import Maingallery from '../../components/Maingallery';
import Index from '../subcatpages/Index';
import Slideshow from '../../components/sliders/MainSlider';
import RecentGallery from '../../components/Recentgallery';
import InteractiveGallery from '../../components/Interactivegallery';
import RandomGallery from '../../components/Randomgallery';

import TodayGallery from '../../components/Todaygallery';

export default function Home() {

  const { str } = ("Today");
  const [value, setValue] = React.useState("Today");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [imgLoaded, setImgLoaded] = React.useState(false);

  return (
    <Page title="Graphic Stock" >
        {!imgLoaded && <Skeleton variant="rect" width="100%" sx={{ paddingTop: '21.53%' }} />}
        <CardMedia
          component="img"
          image={img}
          alt="اصول جرافيك ستوك"
          onLoad={() => setImgLoaded(true)}
          style={{maxHeight :'300px', display: imgLoaded ? 'block' : 'none' }}
        />
        

      <Box sx={{ width: '100%', typography: 'body1' , direction:'ltr'  }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1 , borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Sort by" centered>
                <Tab icon={<TodayOutlinedIcon  />} label= "Today Choice" value="Today" />
                <Tab icon={<AccessTimeOutlinedIcon />} label= "Most Recent " value="recent" />
                <Tab icon={<SentimentVerySatisfiedOutlinedIcon />} label="most interactive" value="interactive" />
                <Tab icon={<SyncProblemOutlinedIcon />} label=" Random Picks " value="Random" />
            </TabList>

          </Box>
          <TabPanel value="Today"><TodayGallery /></TabPanel>
          <TabPanel value="recent"><RecentGallery /></TabPanel>
          <TabPanel value="interactive"><InteractiveGallery /></TabPanel>
          <TabPanel value="Random"><RandomGallery /></TabPanel>
      </TabContext>
    </Box>
    </Page>
  );
}
