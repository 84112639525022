import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import pic from './something-went-wrong.gif';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '80%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SomeThingWrong() {
  return (
    <Page title="something wrong"  >
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', backgroundColor:'white'}}>
       
        
          <Box sx={{maxWidth:'650px' }}>
                <img src={pic} alt='' />
          </Box>
        

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
    </Page>
  );
}
