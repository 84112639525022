import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import ImageListItem from '@mui/material/ImageListItem';
import PropTypes from 'prop-types';

import Lovelist from './file/Lovelist';
import './Maingallery.css'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


GalleryCard.propTypes = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number,
};

function ImageWithPlaceholder({ src, alt, aspectRatio }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <div style={{ 
      borderRadius : 5,
      position: 'relative', 
      width: '100%', 
      paddingBottom: `${100 * aspectRatio}%`
    }}>
      {!loaded && <Skeleton variant="rectangular" width="100%" height="100%" />}
      <img 
    
        src={src} 
        alt={alt} 
        loading="lazy"
        style={{
          borderRadius : "7px 7px 0px 0px", 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          maxWidth: '100%', 
          minWidth: '100%',
          display: loaded ? 'block' : 'none' 
        }} 
        onLoad={() => setLoaded(true)}
      />

    </div>
  );
}



export default function GalleryCard({ file, index ,lovelist }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [love, setLove] = useState(file.user_make_like);
  const [likesCount, setLikesCount] = useState(file.likes);
  const [show, setShow] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  const linkPath = `/file/${file.category.id}/${file.subcat.id}/${file.id}/${file.title}/`;

  const handleShow = () => setShow(true);

  const handleCount = () => {
    if (file.user_make_like === true && love === true) {
      setLikesCount(file.likes);
    } else if (file.user_make_like === false && love === false) {
      setLikesCount(file.likes);
    } else if (file.user_make_like === false && love === true) {
      setLikesCount(file.likes + 1);
    } else if (file.user_make_like === true && love === false) {
      setLikesCount(file.likes - 1);
    }
  };

  const CheckRegesterd = () => {
    if (userInfo) {
      handleLove();
      setLove(!love);
    } else {
      handleShow();
    }
  };

  const handleLove = async () => {
    const likePayload = { like: true };
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/v1/files/file-actions/${file.id}/`, likePayload, config);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleCount();
  }, [CheckRegesterd]);

  useEffect(() => {
    const img = new Image();
    img.src = `${file.thumbnail}?w=248&fit=crop&auto=format`;
    if (img.complete) {
      setImageLoaded(true);
    } else {
      img.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [file]);

  const skeletonHeight = `${file.aspect_ratio * 100}%`; 

  return (
    <Box sx={{  
      borderRadius: 1,
      border:1,  
      borderColor: 'rgb(228, 228, 228)', 
      boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.03)',
        boxShadow: 'rgba(0, 0, 0, .30) 0 10px 20px 0'
      }
    }}>
      <ImageListItem variant="outlined" key={file.id} style={{ width: '100%' }} >
      <a href={linkPath} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ImageWithPlaceholder 
            src={`${file.thumbnail}?w=248&fit=crop&auto=format`} 
            alt={file.title} 
            aspectRatio={file.aspect_ratio} 
          />
          <Stack direction="row" spacing={2} width="100%" >
            <Box sx={{ mt: 1.5, ml: 1, mr: 0 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                {file.owner.profileimage ? (
                  <Avatar alt={file.owner.username} src={file.owner.profileimage} sx={{ border: '1px solid gray', ml: 1, mt: 1.5 }} />
                ) : (
                  <Skeleton variant="circular" width={40} height={40} />
                )}
              </StyledBadge>
            </Box>
            <div className="thumb" style={{ paddingTop: '.75rem' , width:"100%", overflow: 'hidden' }}> 
              <div className='Title' style={{width:'100%'}}>
                <h4 style={{
                  color: 'rgb(37, 37, 37)',
                  fontSize: '1rem',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Amiri',
                  width: '100%'
                }}>{file.title || <Skeleton />}</h4>
                <h6 style={{
                  color: 'rgb(110, 110, 110)',
                  fontSize: '.7rem',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%' 
                }}>By {file.owner.username || <Skeleton />} , In {file.date || <Skeleton />}</h6>
              </div>
            </div>
          </Stack>
        </a>
        <Lovelist fileId={file.id} loveState={file.user_make_like} file={file} index={index} />
      </ImageListItem>
    </Box>
  );
  
  
}