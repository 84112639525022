/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Card, Stack, CardHeader, Divider, Typography } from '@mui/material';

// import {states} from '../../components/EgyptStates';
import axios from 'axios';
import CahrgeBundlesCard from '../components/CahrgeBundlesCard';
import Index from '../components/chargecards/index';
import Card10 from '../components/chargecards/Card10';
import Card25 from '../components/chargecards/Card25';
import Card50 from '../components/chargecards/Card50';


const RechargeWallet = (userInfo) => {
  const [bundelsArray, setBundelsArray] = useState([]);
  const getBundelsData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/bundles/bundles-list/`);
      setBundelsArray(response.data.results);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBundelsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
   
      <Stack width="100%"  alignItems="center" sx={{py:2}}>
        <Typography variant="h5" sx={{ padding: '0.5rem' , borderBottom:2 , borderColor:"gray" }}>
        Choose your suitable card and recharge your wallet now ,,,  
        </Typography>
        {/** 
        <Typography variant="h6" color='green'>
        This offer will end in 23-04-2023 , and your coins will be valid for a life .  
        </Typography>
        */}
      </Stack>
       {/**
      <Card>
        <CardHeader subheader="" title="# Choose your suitable package" />
        <Divider />
        
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: '100%', padding: '10px 0px', flexWrap: 'wrap', p: 0 }}
        >
          {bundelsArray.map((bundle) => 
            (
              <div key={bundle.id}>
                <CahrgeBundlesCard
                  id={bundle.id}
                  title={bundle.title}
                  description={bundle.description}
                  Price={bundle.price}
                  Coins={bundle.coins}
                />
              </div>
            )
          )}
          {/* <CahrgeBundlesCard Price={'10'} Coins={'1000'} />
          <CahrgeBundlesCard Price={'25'} Coins={'2100'} />
          <CahrgeBundlesCard Price={'50'} Coins={'4500'} />
          <CahrgeBundlesCard Price={'100'} Coins={'10000'} /> 
        </Stack>
       
        <Divider />
        <Box
          sx={{
            p: 2,
          }}
        />
      </Card>
       */}
   <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: '100%', flexWrap: 'wrap' }}
        >
          <Index  />
      </Stack>



    </>
  );
};

export default RechargeWallet;
