import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';

import GalleryCard from '../components/GalleryCard';
import { FilesContext } from '../Contexts/filesContext';
import Loader from '../components/loader/Loadmore';

const Searched = () => {
  const [searchVal, setSearchVal] = useState([]);
  const [page, setPage] = useState (2);
  const [more, sethasMore] = useState(true);
  const { searchInput } = useContext(FilesContext);
  const [columns, setColumns] = useState(1);


  const matchesXS = useMediaQuery('(max-width:650px)');
  const matchesSM = useMediaQuery('(min-width:650px) and (max-width:900px)');
  const matchesMD = useMediaQuery('(min-width:900px) and (max-width:1500px)');
  const matchesLG = useMediaQuery('(min-width:1500px)');


    // Check screen size and set columns accordingly
    useEffect(() => {
      if (matchesSM) {
        setColumns(2);
      } else if (matchesMD) {
        setColumns(3);
      } else if (matchesLG) {
        setColumns(4);
      } else {
        setColumns(1);
      }
    }, [matchesXS, matchesSM, matchesMD, matchesLG]);

  const getSearched = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-search/?q=${searchInput}`);
    setSearchVal(response.data.results);
    if (response.data.results.length === 0 || response.data.results.length < 25) {
      sethasMore(false);
    }
  };
  useEffect(() => {
    getSearched();
  }, [searchInput]);

  const fetchٍSearchVal = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-search/?page=${page}&q=${searchInput}`);
    return (res.data.results);
  };

  const fetchData = async () => {
    const newSearchVal = await fetchٍSearchVal();
    setSearchVal([...searchVal, ...newSearchVal]);
    if (newSearchVal.length === 0 || newSearchVal.length < 25) {
      sethasMore(false);
    }
    setPage(page + 1);
  };



  return (
    <>
    <InfiniteScroll
    dataLength={searchVal.length} // This is important field to render the next data
    next={fetchData}
    hasMore={more}
    loader={<Loader />}
    endMessage={
      <p style={{ textAlign: 'center' }}>
        <b> oh ! if you didnot find wat you want ,, please try a nother words </b>
      </p>
    } 
    >
        <Masonry columns={columns} spacing={3}>
          {searchVal.map((file, index) => (
            <GalleryCard key={file.id} file={file} index={index} />
          ))}
        </Masonry>
    </InfiniteScroll>
    </>
  );
          
};

export default Searched;
