import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import pic from './mail.gif';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '80%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResetDone() {
  return (
    <Page title="gratulation">
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', backgroundColor:'white'}}>
        
          <Box sx={{maxWidth:'400px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600'}}>
            We have sent a new password to your mail   <br/> ---------------------- <br/>
            Please check your mail inbox again 
          </Typography>

          <Button to="/login" size="large" variant="contained" component={RouterLink}>
            Go to login page
          </Button>
          
        </ContentStyle>
    </Page>
  );
}
