import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LoupeIcon from '@mui/icons-material/Loupe';
import { Box } from '@mui/system';
import Askwindow from './Askwindow';


export default function Quest() {
  const [quests, setQuests] = useState([]);

  const [showaskwindow, setShowaskwindow] = useState(false);
  const handleClose = () => setShowaskwindow(false);

  const getQuests = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/reports/faqs/`);
      setQuests(response.data.results);
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  useEffect(() => {
    getQuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <div>
            {quests.map((quest, index) => (

      <Accordion key={quest.id} index={index}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{color:'red'}}>{quest.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{color:'green'}}>{quest.answer}</Typography>
        </AccordionDetails>
      </Accordion>
      ))}

     
    <Box sx={{pt:1}}>
        <Button variant="outlined" onClick={() => setShowaskwindow(true)} > <LoupeIcon/>. Ask A Question   .<HelpCenterIcon/></Button>
    </Box>
    <Askwindow
        handleClose={handleClose}

        show={showaskwindow}
      />
    </div>
  );
}