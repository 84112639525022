import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import pic from './mail.gif';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '80%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Gratulation() {
  return (
    <Page title="gratulation">
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', backgroundColor:'white'}}>
        
          <Box sx={{maxWidth:'400px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600'}}>
            greet ,,, now your account is active  <br/> ----------------------
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
    </Page>
  );
}
