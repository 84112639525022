import React, { useEffect, useState , forwardRef  } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import styled from '@emotion/styled';
import { Stack, Typography, Box, Modal, Alert } from '@mui/material';
import './Myprofile.css';
import UserEditForm from '../components/Form/UserEditForm';
import Page from '../components/Page';
import ChangeUserImage from '../components/Form/ChangeUserImage';
import RechargeWallet from './RechargeWallet';
import CoinsIndex from './CoinsComponents/CoinsIndex';
import Quest from '../components/account/quest/Quest';


const UserEditFormWithRef = forwardRef((props, ref) => (
  <UserEditForm ref={ref} {...props} />
));


export default function MyWallet() {
  const [openEdit, setOpenEdit] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [userData, setUserData] = useState({});
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
        config
      );
      setUserData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const updateUserData = async (values) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/v1/accounts/edit-user/${userInfo?.id}/`, values, config);
    } catch (err) {
      console.error(err);
      console.log(err);
    }
  };
  const handleCloseEdit = () => {
    NotificationManager.success('Edit Success', 'Success message');
    setOpenEdit(false);
  };

  useEffect(() => {
    getUserData();
  }, [openEdit, show]);

  const CoverDiv = styled.div`
    background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height:160px;
  `;


  return (
    <Page title="My rofile" className="MyProfile_Page" style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      <div className="row py-5 px-4">
        <div className="col-md-5 mx-auto w-100">
          <div className="bg-white shadow rounded overflow-hidden">
          {userData.isVerified !== true ? ( 
          <Box>
          {userData?.coins > 0 ? (<Box><Alert severity="success">Your wallet is active ... </Alert></Box>):(
          <Alert severity="error" >
            You have 25 Coins ,But your wallet is Inactive<br/>
            Please <a href='/Graphic-Stock/message-confirm-sent' > confirm your mail </a>  to can use it ,, &#9203;
          </Alert>)
          }
        </Box>
          ):(
          <Box>
            <Alert severity="success">Your wallet is active ... </Alert>
          </Box>
          )
          }
            <CoverDiv  >
              <Stack  sx={{alignItems:'center', pt:5}}>
                <Box>
                <Typography variant="h4" sx={{ color: 'white' }}>
                  You Have  
                    <span style={{ color: 'yellowgreen', margin: '.9rem' }}> {userData.coins? (userData.coins):('0')} </span>
                  Valid Coins
                </Typography>
                <Typography sx={{marginLeft:'2rem', marginTop:'.5rem' , color: 'yellow' , fontSize: 'large'}} >Your coins is valid for a life ,  &#128077;</Typography>
                </Box>
              </Stack>
            </CoverDiv>
            <Stack direction="column" justifyContent="space-between" sx={{ padding: '0.5rem 1.5rem', width: '100%' }}>

              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ flexWrap: 'wrap' }}
                className="rounded shadow-sm bg-light p-3 m-1"
              >
              <RechargeWallet userInfo={userInfo} />
                <Box>
                <Typography component="div" sx={{ color: 'black' , fontSize:'1rem', paddingTop:'1rem' , fontWeight:'600'}}>
                  <Alert variant="outlined" severity="info">Also you can recharge your Osoul wallet by mobile wallet or PayPal wallet , Please contact Us on whatsapp No..: 00201124882882</Alert>
                </Typography>

                </Box>
                <Stack sx={{alignItems:'center', pt:2}}>
                  <Typography variant="h6" sx={{ color: 'green' }}>Common Questions || الأسئــلة الشــــائعة</Typography>
                <Quest />
                </Stack>
                <Box   sx={{
                      width: "100%",
                      marginTop: "4rem",
                    }} >
                <CoinsIndex />
                </Box>
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <UserEditFormWithRef success={handleCloseEdit} userData={userData} updateUserData={updateUserData} />
        </Modal>
      </div>
      <NotificationContainer />
      <ChangeUserImage handleClose={handleClose} show={show} />
    </Page>
  );
}
