import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './Maingallery.css';

import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {  Link,  Avatar } from '@mui/material';
// utils

import Lovelist from '../components/file/Lovelist';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 4.5 / 6)',
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 3,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 42,
  height: 42,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-10),
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '130%',
  objectFit: 'cover',
  position: 'absolute',
});

const ChipDiv = styled('div')({
  top: '0',
  backgroundColor: '#FB2576',
  borderRadius: '22px',
  boxSizing: 'border-box',
  color: '#fff',
  fontSize: '13px',
  fontWeight: '400',
  outline: 'none',
  padding: '0px 13px',
  textAlign: 'center',
  transform: 'translateY(0)',
  transition: 'transform 150ms, box-shadow 150ms',
  userSelect: 'none',
  touchaction: 'manipulation',
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
    transform: 'translateY(-2px)',
  },
  '&:focus': {
    boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
    transform: 'translateY(-2px)',
  },
});

// ----------------------------------------------------------------------

SavedCard.propTypes = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function SavedCard({ file, index }) {
  const [likesCount, setLikesCount] = useState(file.likes);
  const [love, setLove] = useState(file.user_make_like);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Navigate = useNavigate();

  //   const { id, image, title, owner, date, user_make_like, likes } = post;
  //   const latestFileLarge = index === 0;
  //   const latestFile = index === 1 || index === 2;

  const latestFileLarge = '';
  const latestFile = '';

  const FILE_INFO = [
    { number: file.coins, icon: 'ph:coins-fill' },
    { number: file.views, icon: 'eva:eye-fill' },
    { number: likesCount, icon: 'eva:heart-fill' },
  ];

  // -----*-----*------*------*Here The Love Action Start*-----*-----*-----*-----*-----

  const handleCount = () => {
    if (file.user_make_like === true && love === true) {
      setLikesCount(file.likes);
    } else if (file.user_make_like === false && love === false) {
      setLikesCount(file.likes);
    } else if (file.user_make_like === false && love === true) {
      setLikesCount(file.likes + 1);
    } else if (file.user_make_like === true && love === false) {
      setLikesCount(file.likes - 1);
    }
  };

  // -----*-----*------*------*Main User Data*-----*-----*-----*-----*-----

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  // -----*-----*------*------*Check If User Is Regesterd*-----*-----*-----*-----*-----
  const CheckRegesterd = () => {
    if (userInfo) {
      handleLove();
      setLove(!love);
    } else {
      handleShow();
    }
  };

  // -----*-----*------*------*Send Love Action*-----*-----*-----*-----*-----

  const handleLove = async () => {
    const likePayload = { like: true };
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/v1/files/file-actions/${file.id}/`, likePayload, config);
    } catch (err) {
      console.error(err);
    }
  };

  // -----*-----*------*------*-----*-----*-----*-----*-----*-----

  useEffect(() => {
    handleCount();
  }, [CheckRegesterd]);

  // -----*-----*------*------*-----*-----*-----*-----*-----*-----

  const ModalContent = {
    title: '⭕ Login Is Needed',
    body: 'Please Login To Like & Download Files You Desire',
    mainButtonName: '👉 Login Now',
    path: '/login',
  };

  return (

          <div className='pics' key={index} >
              <div key={file.id}>
              <a href={`/file/${file.file.category.id}/${file.file.subcat.id}/${file.file.id}/${file.file.title}/`}>
                  <img className='imgx' src={file.file.thumbnail} style={{ width: '100%' }} alt="" />
                </a>
                <div className="Filesumb">
                          <a href="">
                              <img src={file.file.owner.profileimage} alt=""/> 
                          </a>
                          <div className='Title' >  
                              <h4>{file.file.title}</h4>
                              <h6>{file.file.owner.username} , in {file.file.date}  </h6>
                          </div>
                </div>
              </div>
              <Lovelist fileId={file.file.id} loveState={love} file={file.file} index={index} />
          </div>
      )
   
  
}
