import React from 'react';
import { Box } from '@mui/material';


import FD from './FOD/FD';
import OD from './FOD/OD';

function FODetails({file}) {
  return (
      <Box >
        <FD />
        <OD file={file} />
      </Box>
  );
}

export default FODetails;


