// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// state:'disabled' for disabled link
// state:'block' for visable & clickable link

const navConfig = [
  /* ----- important {
    title: 'Main Wall',
    path: '/dashboard/app',
    icon: getIcon('eva:activity-outline'),
    state:'block'
  }, */
  {
    title: 'My Profile',
    path: '/dashboard/profile',
    icon: getIcon('eva:file-text-outline'),
    state:'block'
  },
  {
    title: 'My Wallet',
    path: '/dashboard/wallet',
    icon: getIcon('eva:clipboard-outline'),
    state:'block'
  },
  {
    title: 'Liked Files',
    path: '/dashboard/Liked',
    icon: getIcon('eva:heart-outline'),
    state:'block'
  },
  {
    title: 'Saved Files',
    path: '/dashboard/saved',
    icon: getIcon('eva:star-outline'),
    state: 'block'
  },
  {
    title: 'dashboard',
    path: '/dashboard/mybord',
    icon: getIcon('eva:settings-2-outline'),
    state: 'block',
    
  },
  // {
  //   title: 'Notifications',
  //   path: '/dashboard/notifications',
  //   icon: getIcon('eva:bell-outline'),
  //   state: 'disabled',
  // },
  // {
  //   title: 'My Portfolio',
  //   path: '/dashboard/portifolio',
  //   icon: getIcon('eva:flag-outline'),
  //   state: 'disabled',
  // },



  /* {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  }, */
];

export default navConfig;
