import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { styled } from '@mui/material/styles';

// components
// import Countdown from 'react-countdown';

import {Typography , Stack, Grid, Button , Box , CircularProgress} from '@mui/material';

import Page from '../../components/Page';
import img from './osoul-offer.png'
import ChargeConfirmationModal from '../../components/Form/ChargeConfirmationModal'




const GetButton = styled('button')({
  boxShadow: '0px 3px 2px rgb(204, 204, 204)',
  border: 'none',
  textAlign: 'center',
  backgroundColor: 'rgb(0, 125, 125)', // Original color
  '&:hover': {
    backgroundColor: 'rgb(0, 100, 100)', // Darker color on hover
  },
});


const packagee = {
  id : "3" ,
  price : 14.9 ,
  coins : 5000 , 
  type : 'Hot Offer',
  description : ' It`s offer` ,by 14.9 Dollar , you will get 5000 Osoul Coins , valid for alive'

}

export default function Offers() {

  const [loading, setLoading] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [userData, setUserData] = React.useState({});
  const [showccm, setShowCcm] = useState(false);
  const handleClose = () => setShowCcm(false);
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  const getBundelsData = async () => {
 
    setLoading(true);
    const url = `${process.env.REACT_APP_API_KEY}/api/v1/files/deposit-kashier/3/`;
    const Body = {};
    axios
      .post(url, Body, config)
      .then((result) => {
        const responseUrl = result.data.redirect_iframe_url;
        setLoading(false);
        window.location.replace(responseUrl);
      
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(true);
  };
  
    //  getting sub_User_Data Function
    const getUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
          config
        );
        setUserData(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    useEffect(() => {
      getUserData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Page title="Help Center" >
            <Stack container justifyContent="center" alignItems="center" sx={{p:1 , direction:'rtl'}}>
            <Stack item xs={12} sx={{width:"100%"  , alignItems:'center', minHeight:50, bgcolor:'green'}}>
              <Box >
                <Typography variant='h4' sx={{fontSize:'2rem', fontWeight:'bold', color:'white', pt:1, fontFamily:'Cairo'}}>عرض خــــاص جدا .</Typography>

               </Box>
               </Stack >

               
                          <Grid item xs={12} justifyContent="space-between"  sx={{ direction:'rtl' , margin:2 , padding:2 }} >
                          <Box direction="column" textAlign='center'  display='flex' alignItems='center' justifyContent='center'>
                          <Typography sx={{fontSize:'1.2rem' , fontWeight:'600' , pt:1}}>احصل علي 5000 كوينز صالح للإستخدام في اي وقت مقابل 14.9 دولار ،، </Typography>
                          </Box>
                       
                            
                    </Grid>
                    <Grid item xs={12} maxHeight="400px" >
                            <img style={{maxHeight:'400px'}} src={img} alt="green iguana"/>
                            </Grid>
                    {loading && <CircularProgress sx={{width:'1rem', height:'1rem', color:'green'}}/>}
                    {!loading && !userInfo && <Button sx={{minWidth:'50%', fontSize:'1.3rem' , m:2}} variant="outlined"  to={'/login'} >سجل دخول للحصول على العرض</Button>}
                    <Stack sx={{width:"100%" , alignItems:'center' , mb:5 , bgcolor:'#f8f9fa'}}>
                    {!loading && userInfo && (

                      <GetButton sx={{minWidth:'33%'  ,fontSize:'1.3rem' , bgcolor:'rgb(0, 125, 125)' , borderRadius:.7}} onClick={() => setShowCcm(true)}>
                      <Typography sx={{py:1, color:'white' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >Charge Now</Typography>
                      </GetButton>
                      )}
                        {showccm && <ChargeConfirmationModal
                        packagee = {packagee}
                        userData = {userData}
                        show={showccm}
                        handleClose={handleClose} />}

                    </Stack>
                    </Stack> 
                    <Stack  textAlign='center'>
                          <Typography variant='h5' sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>تذكر ان العرض سينتهي قريب جدا ،،، ولكن رصيدك سيظل صالح مدي الحياة </Typography>
                          <Typography variant='h6' sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>يمكنك ايضا شحن محفظتك من خلال فودافون كاش أو محفظة الهاتف ،،، فقط راسلنا علي واتس اب 01124882882 </Typography>                
                    </Stack>
                               
                
    </Page>
  );
}
