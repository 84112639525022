import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';


import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';

import Loader from '../components/loader/Loadmore';

import Page from '../components/Page';
import MainGallery from '../components/Maingallery';
import GalleryCard from '../components/GalleryCard';
import SavedCard from './Savedcard';

const Savedpage = () => {
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(2);
  const [more, sethasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(1);



  const matchesXS = useMediaQuery('(max-width:600px)');
  const matchesSM = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const matchesMD = useMediaQuery('(min-width:960px) and (max-width:1280px)');
  const matchesLG = useMediaQuery('(min-width:1280px) and (max-width:1600px)');
  const matchesXL = useMediaQuery('(min-width:1600px)');
  
  


    // Check screen size and set columns accordingly
    useEffect(() => {
      if (matchesSM) {
        setColumns(2);
      } else if (matchesMD) {
        setColumns(3);
      } else if (matchesLG) {
        setColumns(3);
      } else if (matchesXL) {
        setColumns(4);
      } else {
        setColumns(1);
      }
    }, [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]);
    

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userFullName = userInfo.name;
  const userFirstName = userFullName.split(' ');

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.access}`,
    },
  };

  const getFiles = async () => {
    setIsLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/downloads-list/`, config);
    if (response.data.results.length === 0 || response.data.results.length < 25) {
      sethasMore(false);
    }
    setFiles(response.data.results);
    setIsLoading(false);
  };

  useEffect(() => {
    getFiles();
  }, []);

  const fetchFiles = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/downloads-list/?page=${page}`, config);
    return res.data.results;
  };

  const fetchData = async () => {
    const newFiles = await fetchFiles();
    const uniqueFiles = [...new Set([...files, ...newFiles])];
    setFiles(uniqueFiles);
    if (newFiles.length === 0 || newFiles.length < 25) {
      sethasMore(false);
    }
    setPage(page + 1);
  };



  let content;
  if (isLoading) {
    content = (
      <Typography variant="h3" style={{ textAlign: 'center', marginTop: '2rem' }}>
        جار التحميل...
      </Typography>
    );
  } else if (files.length === 0) {
    content = (
      <Typography variant="h3" style={{ textAlign: 'center', marginTop: '2rem' }}>
        ليس لديك أي ملفات محفوظة هنا.
      </Typography>
    );
  } else {
    content = (
      <InfiniteScroll
        dataLength={files.length}
        next={fetchData}
        hasMore={more}
        loader={<Loader />}
        endMessage={<p style={{ textAlign: 'center' }}><b>Oh! That`s all </b></p>}
      >
        <Masonry columns={columns} spacing={3.5}  sx={{pl:4 , pt:2}} >
          {files.map((file, index) => (<GalleryCard key={file.file.id} file={file.file} index={index} />))}
        </Masonry>
      </InfiniteScroll>
    );
  }

  return (
    <Page title="Saved page">
      {content}
    </Page>
  );
};

export default Savedpage;
