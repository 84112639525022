import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import React, { useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Box , Stack } from '@mui/material';

// components
import { useSelector } from 'react-redux';
import active from './active.gif'
import pic from './mail2.png';
import pic2 from './mail.png';

import Page from '../../components/Page';

// ----------------------------------------------------------------------







  export default function ConfirmMail (){
    const [con , setCon]=useState(false)
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;


      const confirmHandler = async () => {


        const url =`${process.env.REACT_APP_API_KEY}/api/v1/accounts/send-activation-email/` ;

        const config  = {
          headers : {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userInfo?.access}`,
        }}
    try {
    axios.get(url ,  config)
    .then(response =>
       console.log(response),
       );
       setCon(true);
       console.log(con)
      } catch (err) {
        console.error(err);
        console.log(err);
      }
    };



    const confirmHandler2 = async () => {


      const url =`${process.env.REACT_APP_API_KEY}/api/v1/accounts/send-activation-email/` ;

      const config  = {
        headers : {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo?.access}`,
      }}
  try {
  axios.get(url ,  config)
  .then(response =>
     console.log(response),
     );
     setCon(true);
     console.log(con)
    } catch (err) {
      console.error(err);
      console.log(err);
    }
  };

          
        const ContentStyle = styled('div')(({ theme }) => ({
          maxWidth: '80%',
          margin: 'auto',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: theme.spacing(12, 0),
          marginTop:'-5rem',

        }));


// ----------------------------------------------------------------------

  return (

    userInfo.isVerified === false?(
    <Page title="PlsConfirm">

        {con === false?  (
   <ContentStyle>
          <Stack sx={{alignItems: 'center' }}>
                            <Box sx={{maxWidth:'350px' }}>
                                <img src={pic2} alt='' />
                            </Box>
                            <Box  sx={{ textAlign: 'center', alignItems: 'center'}}>
                            <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600' }}>
                                Please ,, make sure that is your mail  !
                              </Typography>
                            <Typography sx={{ color: 'gray' , fontSize:'1.2rem' , fontWeight:'600'}}>
                                {userInfo?.email} 
                              </Typography>   
                              <Button  onClick={ () =>confirmHandler()} to='#' size="large"  variant="contained"  component={RouterLink} sx={{ padding:'1rem 2rem' , margin:'1.5rem'}}>
                                Send activate mail now
                              </Button>
                            </Box>
                           
          </Stack>
          </ContentStyle>
        ):(
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center'}}>
       
        
          <Box sx={{maxWidth:'350px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600'}}>
            We have send a confirmation message to your email <br/> Please go to your mail inbox and follow the link <br/> ----------------------
          </Typography>

        </ContentStyle>

        )};



    </Page>
    ):(


    
      <Page title="you already active">

        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center'}}>
        <Box sx={{maxWidth:'350px'}}>
              <img src={active} alt='' />
        </Box>
        
        <Typography sx={{ color: 'black' , fontSize:'3rem' , fontWeight:'600'}}>
          Your account is already active <br/> ----------------------
        </Typography>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>

      </ContentStyle>

   



  </Page>



    )
  );
};




















