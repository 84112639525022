import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {  Stack } from '@mui/material';

import img1 from './ld/fanara_lazer.jpeg';
import img2 from './ld/fanos_sora.jpeg';
import img3 from './ld/heart_new_year_box.jpeg';
import img4 from './ld/monazem_adawat_lazer_2_.jpeg';
import img5 from './ld/nardy.jpeg';
import img6 from './ld/office_accessories_set.jpeg';
import img7 from './ld/olan.jpg';
import img8 from './ld/to7fa_lazer.jpeg';
import img9 from './ld/wall_decor_MIAWgzW.jpeg';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'اكسسوارات وديكورات',
    imgPath:img1 ,
  },
  {
    label: 'فانوس رمضان',
    imgPath:img2 ,
  },
  {
    label: 'علب هدايا',
    imgPath:img3 ,
  },
  {
    label: 'مقتنيات واكسسوارات',
    imgPath:img4 ,
  },
  {
    label: 'ادوات والعاب',
    imgPath:img5 ,
  },
  {
    label: 'اطقم مكاتب',
    imgPath:img6 ,
  },
  {
    label: 'ديكورات حوائـط',
    imgPath:img7 ,
  },
  {
    label: 'انتيكات وتحف',
    imgPath:img8 ,
  },
  {
    label: 'ديكورات حوائط',
    imgPath:img9 ,
  },


];

function Ld() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} spacing={5}>
        <Box sx={{ px:1 , minWidth:'45%' }}>
            <Box sx={{backgroundColor: '#006673' , p:1 }}>
            <Typography variant='h5' color='white'> مكتبة تصميمات الليزر </Typography>
            </Box>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  مجموعة متميزة وفريدة من أحدث واجمل التصميمات المخصصة للقص علي ماكينات قطع الليزر بصيغة DXF و CDR يمكنك تنفيذها كما هي او تعديلها وتخصيصها حسب حاجتك .    </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  تحتوي علي تصميمات ديكورات حوائط واكسسوارات وديكورات منزليه وانتيكات وادوات وعلب هدايا      </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  تحتوي ايضا علي تصميمات فوانيس رمضانية واطقم مكاتب مختلفة وحتي بعض تصميمات واعمال الاركت       </Typography>

         </Box>
         <Box sx={{ px:5}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='h6'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Stack>
  );
}

export default Ld;