import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {  Stack } from '@mui/material';


import img1 from './ps/bannar.jpg'
import img2 from './ps/afra7.jpg'
import img3 from './ps/al_aman.jpg'
import img4 from './ps/book_cover.jpg'
import img5 from './ps/business_card.jpg'
import img6 from './ps/emsakea.jpg'
import img7 from './ps/fish_logo_design.jpg'
import img8 from './ps/folders.jpg'
import img9 from './ps/identety.jpg'
import img10 from './ps/label.jpg'
import img11 from './ps/mandel_katb_ketab__2.jpg'
import img12 from './ps/menu.webp'
import img13 from './ps/newspaper.jpg'
import img14 from './ps/one_colour.jpg'
import img15 from './ps/psd_open_source_ad_design_8-min.jpg'
import img16 from './ps/roll_up_design.jpg'
import img17 from './ps/shehadet_takder_3.jpg'
import img18 from './ps/sticker.jpg'
import img19 from './ps/two_colour_4_simple_design.jpg'
import img20 from './ps/more.jpg'





const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'لوحات وبنرات',
    imgPath: img1 ,
   },
  {
    label: 'كروت افراح ',
    imgPath: img2, 
   },
  {
    label: 'رول اب وبوسترات',
    imgPath: img16,
  },
  {
    label: 'بروشورات ومنيوهات',
    imgPath: img12,
  },
 {
    label: 'دواسات سيارات',
    imgPath: img3,
  },
  {
    label: 'اغلفة كتب وملازم',
    imgPath: img4,
  },
  {
    label: 'كروت شخصية',
    imgPath: img5,
  },
  {
    label: 'امساكيات رمضانية',
    imgPath: img6,
  },
  {
    label: 'شعارات ',
    imgPath: img7,
  },
  {
    label: 'فولدرات',
    imgPath: img8,
  },
  {
    label: 'هويات متكاملة',
    imgPath: img9,
  },
  {
    label: 'اغلفة منتجات',
    imgPath: img10,
  },
  {
    label: 'مناديل كتب كتاب',
    imgPath: img11,
  },
  {
    label: 'جرائد ومجلات',
    imgPath: img13,
  },
  {
    label: 'اعلانات واحد لون',
    imgPath: img14,
  },
  {
    label: 'اعلانات 4 لون ',
    imgPath: img15,
  },
  {
    label: 'شهادات تقدير',
    imgPath: img17,
  },
  {
    label: 'استيكرات',
    imgPath: img18,
  },
  {
    label: 'اعلانات 2 لون',
    imgPath: img19,
  },
  {
    label: 'الكثير والكثير',
    imgPath: img20,
  },






];

function Ps() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} spacing={5}>
        <Box sx={{ px:1 , minWidth:'45%' }}>
            <Box sx={{backgroundColor: '#006673' , p:1 }}>
              <Typography variant='h5' color='white' > قسـم تصميمات الدعاية والاعــلان . </Typography>
            </Box>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}>  تصميمات تجارية واعلانية مطبوعة ومرئية ذات طابع شرقي ولغة عربية ، محتوي ضخم ومتنوع الاشكال والاذواق والاختصاصات   </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}>  تعتبر مرجع ضخم يضم امثلة ومقاسات واشكال ومسميات ومئات التصميمات الجاهزة لكل بند من البنود التي من الممكن ان ينفذها مكاتب الدعاية والاعلان أو المطابع  </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}> تصميمات للمطبوعات الاعلانية : فلاير - بروشور - اعلان ورقي واحد لون ؛2 لون ؛4لون - مجلات وجرائد - دعوات   </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}>  تصميمات للمطبوعات التجارية : بلوك نوت - روشتات اطباء - فولدرات - شهادات - كروت شخصية وكروت اعمال - ملصقات تجارية   </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}> تصميمات لمطبوعات الاوت دور والان درو : لوحات ولافتات - رول اب وبوسترات     </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}> تصميمات لمطبوعات السبلمشن : تيشرتات - مجات - هدايا - اعلام     </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600'  , p:1 }}> تصميمات اعلانات الميديا : اعلان فيس بوك - كفر فيس بوك - اعلان انستجرام      </Typography>

         </Box>
         <Box sx={{ px:5}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='h6'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Stack>
  );
}

export default Ps;