import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {  Stack } from '@mui/material';

import img1 from './wd/eslamic_tablou_1013-min.jpeg'
import img2 from './wd/tablou_design_psd_23.jpeg'
import img3 from './wd/tablou_design_psd_25.jpeg'
import img4 from './wd/tablou_design_psd_28.jpeg'
import img5 from './wd/tablou_design_psd_30.jpeg'
import img6 from './wd/tablou_design_psd_29.jpeg'
import img7 from './wd/tablou_eslami_modern_2.jpg'
import img8 from './wd/tablou_eslami_modern_5.jpg'



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'تابلوهات دينية',
    imgPath: img1,
  },
  {
    label: 'تابلوهات مودرن',
    imgPath: img2,
  },
  {
    label: 'تابلوهات ثري دي',
    imgPath: img3,
  },
  {
    label: 'تابلوهات ثلاثية',
    imgPath: img4,
  },
  {
    label: 'تابلوهات ريابعية ',
    imgPath: img5,
  },
  {
    label: 'تابلوهات ثــري دي',
    imgPath: img6,
  },
  {
    label: 'تابلوهات دينـية',
    imgPath: img7,
  },
  {
    label: 'تابلوهات إسلامية',
    imgPath: img8,
  },
];

function Wd() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} spacing={5}>
        <Box sx={{ px:1 , minWidth:'45%' }}>
            <Box sx={{backgroundColor: '#006673' , p:1 }}>
            <Typography variant='h5' color='white'> مكتبة تصميمات التابلوهات </Typography>
            </Box>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  تعتبر صناعة التابلوهات من المشاريع النشطه في الوقت الحالي ، ولعل اهم مايهم العميل هو التصميم الجذاب ، واكثر مايرهق صاحب العمل في مجال صناعة التابلوهات هو التصميم ايضا     </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  اليك هنا ضمن المحتوي الموجود علي اصول جرافيك اجمل واروع مجموعة من تصميمات التابلوهات المتنوعة التي تناسب اغلب الاذواق      </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}> تصميمات تابلوهات حديثة قطعة واحده او ثلاث قطع او خمس قطع       </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}> تصميمات تابلوهات دينية ورسومات ومخطوطات والعديد من التابلوهات المودرن والكلاسيكية النادرة       </Typography>

         </Box>
         <Box sx={{ px:5}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='h6'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Stack>
  );
}

export default Wd;