// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// state:'disabled' for disabled link
// state:'block' for visable & clickable link

const navConfig = [
  {
    title: 'home page',
    path: '/Graphic-Stock',
    icon: getIcon('eva:home-outline'),
    state: 'block',
  },

 
];

export default navConfig;
