import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import axios from 'axios';


// @mui
import { styled } from '@mui/material/styles';

import { Button, Link, Stack, TextField, Typography, Alert, AlertTitle , Box} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../components/Page';

import { login } from '../../redux/actions/authActions';
import pic from './mail2.png';

// ----------------------------------------------------------------------



const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

export default function ResetPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const userLogin = useSelector((state) => state.userLogin);
  const { error: loginError, loading: loginLoading, userInfo } = userLogin;

  // const [showPassword, setShowPassword] = useState(false);
  const [res , setRes] = useState(false);

  const MailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: MailSchema,
    onSubmit: () => {
      dispatch(login(values.email));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const resetHandler = () => {

    const url =`${process.env.REACT_APP_API_KEY}/api/v1/accounts/send-reset-password-email/` ;
    const email = values.email;
    console.log(email);


    try {
        axios.post(url , {email}  )
        .then(response =>
           console.log(response),
           );
           
        setRes(true);

          } catch (err) {
            console.error(err);
            console.log(err);
          }




};

  useEffect(() => {
    if (userInfo) {
      navigate('/', { replace: true });
    }
    
    
  }, [navigate, userInfo]);

  return ( 
    <Page title="reset password">
    {res === false ? (

    <FormikProvider value={formik}>

          <ContentStyle>
            <Typography variant="h2" gutterBottom>
              Reset Password
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your Email  below.</Typography>
    

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} paddingBottom={'3rem'} >
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"

            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

         
        </Stack>

        {loginError ? (
          <Alert severity="error">
            <AlertTitle>Login Error</AlertTitle>
            {loginError}
          </Alert>
        ) : null}

        
        <Button  onClick={() =>resetHandler() } fullWidth  size="large" to="" variant="contained"   component={RouterLink} >
                    Reset my password
        </Button>
        <Typography variant="body2" paddingTop={'2rem'} >
            Don’t have an account? {''}
            <Link variant="subtitle2" component={RouterLink} to="/register">
              Get started
            </Link>
          </Typography>

      </Form>
      
      </ContentStyle>
      

    </FormikProvider>
    
          
    ):(
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center'}}>
       
        
          <Box sx={{maxWidth:'350px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600'}}>
            We have send a reset password message to your email <br/> Please go to your mail inbox and follow the link <br/> ----------------------
          </Typography>
          

        </ContentStyle>

    )}; 
    </Page>

  );
}
