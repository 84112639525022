import { Grid, Container, Typography, Card, CardContent, Box, Button, CardActions, Link, Stack } from '@mui/material';
// components
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Page from '../components/Page';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [message, setMessage] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getMessages = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/messages-list/`);
    setMessage(response.data.results);
  };
  useEffect(() => {
    getMessages();
  },[]);

  
  const textWithoutTags = (message) => {
    const oldText = message;
    return oldText.replace(/(<([^>]+)>)/gi, '');
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome {userInfo.name} 👋
        </Typography>

        <Stack sx={{ flexDirection: { xs: 'column-reverse', lg: 'row' } }} gap={3}>
          <Box flex={6}>
            {/* <Grid container spacing={2}> */}
            <Grid item xs={12} md={4} lg={11} mb={2}>
              <Card>
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                  <Typography variant="p" sx={{ mb: 5 }}>
                    Its A trial run 🚀
                  </Typography>
                  Let us know what you think about new osoul GS world
                  <Typography>
                    Please make sure to{' '}
                    <Link href="#" target="_blank">
                      Update your profile ,
                    </Link>
                    to let our algorithms Serve you to the fullest
                  </Typography>
                  <Box m={2} pt={3}>
                    <Button href="#" target="_blank" variant="outlined">
                      Get more information
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} md={4} lg={11} mb={2}>
              <Card>
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                  <Typography variant="p" sx={{ mb: 5 }}>
                    Now, you have 200 coins you can use it to download a handerds of our designs
                  </Typography>
                  , you must loved
                  <Typography>
                    You also can{' '}
                    <Link href="#" target="_blank">
                      Charge your wallet ,
                    </Link>
                    to get full data and serveces access
                  </Typography>
                  <Box m={2} pt={3}>
                    <Button href="#" target="_blank" variant="outlined">
                      Charge my wallet
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            {/* new Messgae From Us */}
            {message.map((message) => {
              return (
                <Grid item xs={12} md={4} lg={11} mb={2} key={message.id}>
                  <Card>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        @osoul world
                      </Typography>
                      <Typography variant="h5" component="div">
                        ⭐️ New Message For {userInfo.name}.
                      </Typography>

                      <Typography variant="body2" sx={{ paddingRight: '3rem' }}>
                        {textWithoutTags(message.message)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Box m={1} pt={0}>
                        <Button href="#" target="_blank" variant="outlined">
                          {message.button_title}
                        </Button>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
            {/* new Messgae From Us */}
          </Box>

          <Box flex={3}>
            {/* <Grid container spacing={2}> */}
            <Grid item md={4} lg={14} mb={2}>
              <Card>
                {' '}
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    @osoul world
                  </Typography>
                  <Typography variant="h5" component="div">
                    Give a ⭐️ if this site helped you!
                  </Typography>

                  <Typography variant="body2">
                    If you have find any type of Bug, Raise an Issue So we can fix it
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box m={1} pt={0}>
                    <Button href="#" target="_blank" variant="outlined">
                      whatsApp
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>

            <Grid item md={4} lg={12} mb={2}>
              <Card>
                {' '}
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    @osoul world
                  </Typography>
                  <Typography variant="h5" component="div">
                    Give a ⭐️ if this site helped you!
                  </Typography>

                  <Typography variant="body2">
                    If you have find any type of Bug, Raise an Issue So we can fix it
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box m={1} pt={0}>
                    <Button href="#" target="_blank" variant="outlined">
                      E-mail
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
            {/* </Grid> */}
          </Box>

          {/* </Grid> */}
        </Stack>
      </Container>
    </Page>
  );
}
