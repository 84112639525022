
// components
import {Typography , Stack, Container , Box , Card , CardMedia , CardActionArea , CardContent} from '@mui/material';

import Page from '../../components/Page';
import Ps from './contentsliders/Ps';
import Db from './contentsliders/Db';


import MainTabHelp from './Maintabhelp';
import img from './osoul-idea.JPG'
import Wp from './contentsliders/wp';
import Wd from './contentsliders/wd';
import Ld from './contentsliders/ld';
// ----------------------------------------------------------------------


export default function Content() {





  return (
    <Page title="Help Center" >
              <Stack direction="column" spacing={5}  sx={{width:'100%', display:'flex',backgroundColor:'wight' }} >
                  <Box sx={{ borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0'}}>
                    <Ps/>
                  </Box>
                  <Box sx={{ borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0'}}>
                    <Db/>
                  </Box>
                  <Box sx={{ borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0'}}>
                    <Wp/>
                  </Box>
                  <Box sx={{ borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0'}}>
                    <Wd/>
                  </Box>
                  <Box sx={{ borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0'}}>
                    <Ld/>
                  </Box>
                  <Box> </Box>
            </Stack>
    </Page>
  );
}
