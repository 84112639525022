
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Typography , Alert , TextField , Box , Stack} from '@mui/material';
import { useFormik } from 'formik';

import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaCoins } from 'react-icons/fa';
import { styled } from '@mui/material/styles';

// -------------------------------------------
const DownloadButton = styled('button')({
  backgroundColor: '#13aa52',
  border: '1px solid #13aa52',
  borderRadius: '4px',
  boxShadow: 'rgba(0, 0, 0, .1) 0 2px 4px 0',
  boxSizing: 'border-box',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '16px',
  fontWeight: '400',
  outline: 'none',
  padding: '7px 25px',
  textAlign: 'center',
  transform: 'translateY(0)',
  transition: 'transform 150ms, box-shadow 150ms',
  userSelect: 'none',
  touchaction: 'manipulation',
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
    transform: 'translateY(-2px)',
  },
  '&:focus': {
    boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
    transform: 'translateY(-2px)',
  },
});
// -------------------------------------------

export default function Askwindow({ handleClose, show, userverified }) {
  const Navigate = useNavigate();
  // ----------------------------------------------------
  const [question, setQuestion] = useState('');
  const [done , setDone] = useState(false); 

  // -----------------------------------------------------
  // -----------------------------------------------------
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  const handleChange = (event) => {
    // 👇 Get input value from "event"
    setQuestion(event.target.value);
  };

  const handleDone = () =>{
    setDone(true)
  };
  const handleClear =() =>{
    setDone(false)
  };
  // ------------Geting File Source -----------
  const postQuestion = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/v1/reports/faqs/`,{question}, config);

      setTimeout(() => {
        // window.location.replace(`${fileSource}`);
        window.open('http://localhost:3000/help-center/site/');
      }, 500);


      

    } catch (err) {
      console.error(err);
    }
  };
  // --------if no Balance -----------------
  
  // ----------------------------------------
  // balanceAfter



  return (
    <>

      <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton>

          <Modal.Title sx={{ fontWeight: 'bold' }}>Ask a Question !</Modal.Title>
        </Modal.Header>
        {done === false ? (

        <Modal.Body>
            <FormGroup>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '90%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
     
   
        <TextField
          id="outlined-multiline-static"
          label="Type your question,, Type by any language ."
          multiline 
          rows={4}
          defaultValue=""
          onChange={handleChange}
        />
      </div>
   

    </Box>
            </FormGroup>
    


        </Modal.Body>
                      ):(
                        <Modal.Body>
                        <FormGroup>
                        <Box >
                        <Typography variant='h5'> We are recived your question , thank you for your time .</Typography>                       
            
                       </Box>
                        </FormGroup>
                
            
            
                    </Modal.Body> 
                      )}

       
        <Modal.Footer >
        <Alert severity="success" sx={{p:0.3}}>We will look at your question soon ,,, and we might send you an email message .</Alert>


          {done === false  && question.length >10 ? (<DownloadButton onClick={() => {postQuestion();handleDone()} } >Send</DownloadButton>):(<DownloadButton onClick={() => {handleClear();handleClose()} } >Done</DownloadButton>)}
        </Modal.Footer>

      </Modal>

    </>
  );
}
