import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// ------
import { Typography, Stack, Box, Avatar } from '@mui/material';

const Filesumb = ({ file }) => {


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const fontSize = matches ? '1rem' : '1.1rem';


  if (file?.id)
    return (
      <Stack direction="row" alignItems="center">
        <Box sx={{ margin: '5px' }}>
          <a href="">
            {file?.type.map((type) => {
              return (
                <div key={type.id}>
                  <Avatar alt={type.typetitle} src={type.typelogo} />
                </div>
              );
            })}
          </a>
        </Box>
        <Box sx={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>
          <Typography variant="h1" fontFamily='Amiri' style={{ fontSize }} >{file?.title}</Typography>
          <Typography variant="body1" style={{ fontSize }} fontFamily='Amiri'>{file?.description}</Typography>
        </Box>
      </Stack>
    );
};

export default Filesumb;
