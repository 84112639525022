
// components
import {Typography , Stack, Container , Box , Card , CardMedia , CardActionArea , CardContent} from '@mui/material';

import Page from '../../components/Page';
import IdeaSlider from './Ideaslider';
import MainTabHelp from './Maintabhelp';
import img from './osoulworld.webp'
import img2 from './mo_soliman.jpg'
import img3 from './contentsliders/creators/m elshaer.JPG'
import img4 from './contentsliders/creators/m gamel.JPG'
import img5 from './contentsliders/creators/m mousa.JPG'
import img6 from './contentsliders/creators/khaled.JPG'
import img7 from './contentsliders/creators/m rabe3.JPG'



// ----------------------------------------------------------------------


export default function Creators() {




  return (
    <Page title="Help Center" >
            <Stack sx={{p:1 , direction:'rtl'}}>
            <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1,  border: 1 , borderColor: 'divider'}}>
              <Box sx={{width:1000 }}>
                    <CardMedia
                      component="img"
                      height="300"
                      image={img}
                      alt="green iguana"
                    />
                    </Box>
                <Box sx={{pr:2}} >
                <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}>فريق العمل ،،، والشركة</Typography>

                  <Typography fontSize="1.2rem" sx={{ borderBottom: 1 , borderColor: 'divider' , p:1 }}> نحن فريق عمل مبدع يعشق ما يقوم به ويعتز بالشراكات التي يعقدها مع العملاء ويعتز بكل علاقة عمل صنعها باحترافية ومصداقية نسعي دائما لنكون شركاء في النجاح هدفنا هو نجاح عملائنا ودعم استراتيجياتهم وابتكار وسائل وأفكار حديثة للتواصل مع عملائهم ..</Typography>
                  <Typography fontSize="1.2rem" sx={{ borderBottom: 1 , borderColor: 'divider' , p:1}}> شركتنا متخصصة بالاتصال والابداع ملتزمون بتقديم الأفكار الجديدة بأعلي معايير الجودة في خدمات التصميم الجرافيكي والاعلان والتسويق الالكتروني والعلاقات العامة. نحن نفكر دائما خارج المعهود لتقديم خدمات ذات مصداقية تحقق الأهداف المرجوة لعملائنا وتساعدنا علي تخطي جميع توقعاتهم.</Typography>
                  <Typography fontSize="1.2rem" sx={{ borderBottom: 1 , borderColor: 'divider' , p:1}}>هدفنا هو خدمة اكبر عدد ممكن العاملين بمجال الدعاية والاعلان والتصميم الجرافيكي وتسهيل عملهم واختصار اوقاتهم</Typography>
                  <Typography fontSize="1.2rem" sx={{ borderBottom: 1 , borderColor: 'divider' , p:1}}>رسالتنا هي محاولة ارثاء المحتوي العربي علي الانترنت وتقديم اعمال تعود بالربح والنفع للعديد</Typography>

                </Box>
              </Stack>
              <Stack sx={{width:"100%"  , alignItems:'center', minHeight:40, bgcolor:'#006673' , my:2}}>
              <Box >
                 <Typography variant='h4' color="white" sx={{}}>مُبدعون ساهموا في صناعة محتوي اصول </Typography>

                </Box>
            

              </Stack>
              <Stack direction="row" spacing={1.5} sx={{width:'100%', display:'flex', overflowX:'scroll' ,backgroundColor:'wight' }} >
              <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img2}
                      alt="green iguana"
                    />
                    <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>mohamed soliman</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
                    </Box>
                    <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img4}
                      alt="green iguana"
                    />
                      <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>mohamed gamel</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
                    </Box>
                    <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img3}
                      alt="green iguana"
                    />
                      <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>mohamed elshaaer</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
                    </Box>
                   
                    <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img5}
                      alt="green iguana"
                    />
                      <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>mo`men mousa</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
                    </Box>
                    <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img6}
                      alt="green iguana"
                    />
                      <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>khaled mohamed</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
                    </Box>
                    <Box sx={{minWidth:200 , mx:1 , px:1 , boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={img7}
                      alt="green iguana"
                    />
                      <Box sx={{height:60, direction:'ltr'}}>
                      <Typography variant='h7'>mohamed rabeea</Typography>
                      <Typography >Graphic Designer</Typography>

                    </Box>
         
                    </Box>
              </Stack>

            </Stack>
    </Page>
  );
}
