import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, forwardRef  } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import styled from '@emotion/styled';
import { Stack, Typography, Box, Button, Modal, Alert } from '@mui/material';
import { FaCoins } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import './Myprofile.css';
import UserEditForm from '../components/Form/UserEditForm';
import Page from '../components/Page';
import ScopeList from '../components/ScopeList';
import ChangeUserImage from '../components/Form/ChangeUserImage';
import RechargeWallet from './RechargeWallet';
import Inbox from '../components/account/inbox/Inbox';
import PersonalInfoStepper from '../components/account/info/PersonalInfoStepper';

const UserEditFormWithRef = forwardRef((props, ref) => (
  <UserEditForm ref={ref} {...props} />
));

export default function Myprofile() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [userData, setUserData] = useState({});
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
        config
      );
      setUserData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const updateUserData = async (values) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/v1/accounts/edit-user/${userInfo?.id}/`, values, config);
    } catch (err) {
      console.error(err);
      console.log(err);
    }
  };



  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    NotificationManager.success('Edit Success', 'Success message');
    setOpenEdit(false);
  };

  useEffect(() => {
    getUserData();
  }, [openEdit, show]);







  const ProfileHeadDiv = styled.div`
    transform: translateY(5.5rem);
    
  `;
  const MiddleContainerDiv = styled.div`
    &:hover {
      border: 0px solid #006673;
    }
  `;
  const CoverDiv = styled.div`
    background-image: url(https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80);
    background-size: cover;
    background-repeat: no-repeat;
  `;
  const RoundDiv = styled.div`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `;

  return (
    <Page title="My rofile" className="MyProfile_Page" style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      <div className="row py-5 px-4">
        <div className="col-md-5 mx-auto w-100">
          <div className="bg-white shadow rounded overflow-hidden">
            <CoverDiv className="px-4 pt-0 pb-4 cover">
              <ProfileHeadDiv className="media d-flex align-items-end profile-head">
                <Box onClick={() => handleShow()} className="profile mr-3">
                  <img
                    src={
                      userInfo?.avatarUrl !== '/media/placeholder.png'
                        ? `${process.env.REACT_APP_API_KEY}${userInfo?.avatarUrl}`
                        : 'https://gs.osoulworld.com/static/media/Advertising_agency.9bbc08894ccef2517c34.webp'
                    }
                    alt="photoURL"
                    width="130"
                    className="rounded mb-5 img-thumbnail"
                  />
                </Box>
                <div className="info-section mb-5 ps-3 text-white" style={{ cursor: 'pointer' }}>
                  <Stack direction="row" gap={2}>
                    <Typography variant="h6" as="h6" sx={{ fontWeight: '300' }}>
                      {userData?.first_name} {userData?.last_name}
                    </Typography>
                    <FiEdit3 onClick={() => handleOpenEdit()} color="#dee2e6" />
                  </Stack>
                  <Stack direction="row" gap={2}>
                    <Typography variant="body1" sx={{ fontWeight: '200', marginBottom: '1rem' }}>
                      {userData?.company !== 'NA' ? userData?.company : ''}
                    </Typography>
                  </Stack>
                </div>
              </ProfileHeadDiv>

            </CoverDiv>
            <div className="bg-light p-4 d-flex justify-content-end text-center">
              <ul className="list-inline mb-0" style={{ zIndex: '2' }}>
                <li className="list-inline-item">
                  <Box component={RouterLink} to={'/dashboard/profile'} sx={{ color: 'black', textDecoration: 'none' }}>
                    <h5 className="font-weight-bold mb-0 d-block">{userData?.photos}</h5>
                    <small className="text-muted"> Owen files ||</small>
                  </Box>
                </li>
                <li className="list-inline-item">
                  <Box component={RouterLink} to={'/dashboard/Liked'} sx={{ color: 'black', textDecoration: 'none' }}>
                    <h5 className="font-weight-bold mb-0 d-block">{userData?.likes}</h5>
                    <small className="text-muted"> Liked items ||</small>
                  </Box>
                </li>
                <li className="list-inline-item">
                  <Box component={RouterLink} to={'/dashboard/saved'} sx={{ color: 'black', textDecoration: 'none' }}>
                    <h5 className="font-weight-bold mb-0 d-block">{userData?.downloads}</h5>
                    <small className="text-muted"> Downloads</small>
                  </Box>
                </li>
              </ul>
            </div>
 
            <Stack direction="column" justifyContent="space-between" sx={{ padding: '0.5rem 1.5rem', width: '100%' }}>
              <Box className="p-3 m-1 rounded shadow-sm bg-light">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: { lg: 'nowrap', md: 'nowrap', sm: 'wrap', xs: 'wrap' } }}
                >
                  
                  <Box>
                    <Typography className="font-italic mb-0">{userData?.role}</Typography>
                    <Typography className="font-italic mb-0">
                      Lives in {userData?.country !== null ? userData?.country : '...'},{' '}
                      {userData?.city !== null ? userData?.city : null}{' '}
                    </Typography>
                    <Typography className="font-italic mb-0">
                      Working at : {userData?.company !== 'NA' ? userData?.company : ''}
                    </Typography>
                    <Typography className="font-italic mb-0">
                      Phone number : {userData?.mobile_number !== 'NA' ? userData?.mobile_number : ''}
                    </Typography>
                  </Box>
                  <Button onClick={handleOpenEdit} gap={1}>              
                  <FiEdit3 color="black" />
                </Button>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: { lg: 'nowrap', md: 'nowrap', sm: 'wrap', xs: 'wrap' } }}
                >


                <Box
                    onClick={() => Navigate('/dashboard/wallet')}
                    sx={{
                      width: { xs: 'max-content' },
                      cursor: 'pointer',
                      margin: { xs: '0rem 0rem', md: '0rem', lg: '0rem' },
                    }}
                  >
                    <MiddleContainerDiv
                      className="middle-container d-flex justify-content-between align-items-center mt-3 p-2"
                      style={{ backgroundColor: '#006673', borderRadius: '8px' }}
                    >
                      <div
                        className="dollar-div px-3"
                        style={{
                          backgroundColor: '#fff',
                          borderRadius: '5px',
                          padding:'2px'
                        }}
                      >
                        <RoundDiv className="round-div">
                          <FaCoins />{' '}
                        </RoundDiv>
                      </div>
                      <div className="d-flex flex-column text-right ms-2">
                        <span
                          className="current-balance"
                          style={{ fontSize: '14px', color: '#ffff', fontWeight: '600' }}
                        >
                          You have
                        </span>
                        <span className="amount" style={{ color: '#fff', fontSize: '14px', fontWeight: '500' }}>
                          {userData.coins? (userData.coins):("0")} <span> Coins</span>
                        </span>
                      </div>
                    </MiddleContainerDiv>
                  </Box>


                {userInfo.isVerified !== true ? ( 
                  <>
                    


                    <Box>
                    {userData?.coins > 0 ? (<Box><Alert severity="success">Your wallet is active ... </Alert></Box>):(
                    <Alert severity="error" >
                      You have 25 Coins ,But your wallet is Inactive<br/>Please <a href='/Graphic-Stock/message-confirm-sent' > confirm your mail </a>  to can use it ,, &#9203;
                    </Alert>)
                    }
                  </Box>

                  </>
                
                ):(
                  <Box>
                            <Alert severity="success">Your wallet is active ... </Alert>
                </Box>


                )
               }
                


                  </Stack>

              </Box>
              
              {/* info div */}
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ flexWrap: 'wrap' }}
                className="rounded shadow-sm bg-light p-3 m-1"
              >
                
                <Stack
                  justifyContent="space-evenly"
                  alignItems="center"
                  sx={{ flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' } }}
                >

          
                  <Box
                    sx={{
                      width: '100%',
                      cursor: 'pointer',
                      margin: { xs: '1rem 0rem', md: '0rem', lg: '0rem' },
                    }}
                  >

                    <Inbox  config={config} />
                    
                  </Box>

                </Stack>
               

              </Stack>
            </Stack>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <UserEditFormWithRef success={handleCloseEdit} userData={userData} updateUserData={updateUserData} />
        </Modal>
      </div>
      <NotificationContainer />
      <ChangeUserImage handleClose={handleClose} show={show} />
    </Page>
  );
}
