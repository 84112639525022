import { Navigate, useRoutes } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
import MoreLayout from '../layouts/more';
import HelpLayout from '../layouts/help';

//
import User from '../pages/User';
import Login from '../pages/Login';
import NotFound from '../pages/Page404';
import Register from '../pages/Register';
import PlsConfirm from '../pages/onemessagepage/PlsConfirm';
import Gratulation from '../pages/onemessagepage/Gratulation';
import SomeThingWrong from '../pages/onemessagepage/SomeThingWrong';
import ThankYou from '../pages/onemessagepage/ThankYou';
import ConfirmMail from '../pages/onemessagepage/ConfirmMail';
import ResetPass from '../pages/onemessagepage/ResetPassword';
import ResetDone from '../pages/onemessagepage/ResetPasswordDone';




import DashboardApp from '../pages/DashboardApp';
import Home from '../pages/mainpages/Home';
import Psdtemplate from '../pages/catpages/Psd-template';
import WallPaper from '../pages/catpages/Wall-paper';
import DesignerBag from '../pages/catpages/Designer-bag';
import Tablou from '../pages/catpages/Tablou';
import Laser from '../pages/catpages/Laser';

import FileMore from '../pages/mainpages/FileAndMore';
import Myprofile from '../pages/Myprofile';
import MyWallet from '../pages/MyWallet';

import Likedpage from '../pages/accountpages/Likedpage';
import Notifications from '../pages/Notification';
import Savedpage from '../pages/Savedpage';
import Myportifolio from '../pages/Myportifolio';
import Dashboard from '../pages/Dashboard';
import Searched from '../pages/Searched';
import CreatorProfile from '../pages/CreatorPages/CreatorProfile';
import CreatorStepper from '../pages/CreatorPages/CreatorStepper';
import RechargeWallet from '../pages/RechargeWallet';
import AllCreatorsPage from '../pages/CreatorPages/CreatorOpenPages/AllCreatorsPage';
import SubCatPage from '../pages/subcatpages/SubCatPage';
import ScopePage from '../pages/scopepages/Scopepage';

import AllSubCat from '../pages/subcatpages/AllSubCat';
import AllScopes from '../pages/scopepages/AllScope';

import PsIndex from '../pages/osoulps/Index';
import Privacy from '../pages/Privacy';
import HelpCenter from '../pages/help/Help';
// ----------------------------------------------------------------------


export default function Router() {




  return useRoutes([

    {
      path: '/file',
      element: <MainLayout />,
      children: [
        { path: ':categoryid/:subcatid/:id/:str', element: <FileMore /> },
        { path: ':categoryid/:id/:str', element: <FileMore /> },
        { path: ':id/', element: <FileMore /> },
    ],
    },
    
    {
      path: '/help-center',
      element: <HelpLayout />,
      children: [
        { path: '', element: <HelpCenter /> },
        { path: ':str', element: <HelpCenter /> },
        { path: ':id', element: <HelpCenter /> },
    ],
    },

    {
      path: '/files',
      element: <MainLayout />,
      children: [

        { path: 'psd-template', element: <Psdtemplate /> },
        { path: 'designer-bag', element: <DesignerBag /> },
        { path: 'full-wall-paper', element: <WallPaper /> },
        { path: 'wall-decoration', element: <Tablou /> },
        { path: 'laser-art-cut', element: <Laser /> },
        { path: 'all-subcategory', element: <AllSubCat /> },
        { path: 'all-scopes', element: <AllScopes /> },

        { path: 'subcategory/:id/:str/', element: <SubCatPage /> },
        { path: 'scope/:id/:str/', element: <ScopePage /> },
        { path: 'scope/:id/', element: <ScopePage /> },

        { path: 'subcategory/:id/', element: <SubCatPage /> },

        { path: 'creators', element: <AllCreatorsPage /> },
      ],
    },

    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'mybord', element: <Dashboard /> },
        { path: 'portifolio', element: <Myportifolio /> },
        { path: 'saved', element: <Savedpage /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'liked', element: <Likedpage /> },
        { path: 'profile', element: <Myprofile /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'creatorprofile', element: <CreatorProfile /> },
        { path: 'creator-assign', element: <CreatorStepper /> },
        { path: 'searched/:val', element: <Searched /> },
        { path: 'recharge', element: <RechargeWallet /> },
        { path: 'wallet', element: <MyWallet /> },

      ],
    },

    {
      path: '/Graphic-Stock',
      element: <MainLayout />,
      children: [
        { path: '', element: <Home /> },
        { path: 'searched/:val', element: <Searched /> },
        { path: 'thank-you', element: <ThankYou /> },
        { path: 'message-confirm-sent', element: <ConfirmMail /> },




      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/Graphic-Stock" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'plsconfirm', element: <PlsConfirm /> },
        { path: 'gratulation', element: <Gratulation /> },
        { path: 'somethingwrong', element: <SomeThingWrong /> },
        { path: 'reset-my-password', element: <ResetPass /> },
        { path: 'reset-password-done', element: <ResetDone /> },
        { path: 'smart-ps', element: <PsIndex /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'Privacy-policy', element: <Privacy /> },

      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
