import * as React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Idea from './idea';
import Site from './Site'
import Content from './Content';
import Creators from './Creators';
import Offers from './Offers';
import ContactUS from './Contactus';

export default function MainTabHelp() {
  const { str } = useParams();
  const [value, setValue] = React.useState(str);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' ,direction:'rtl' }}>
      <TabContext value={value}>
  
        <TabPanel value="idea"><Idea /></TabPanel>
        <TabPanel value="site"><Site /></TabPanel>
        <TabPanel value="content"><Content /></TabPanel>
        <TabPanel value="creators"><Creators /></TabPanel>
        <TabPanel value="offers"><Offers /></TabPanel>
        <TabPanel value="contact"><ContactUS /></TabPanel>

        <Box sx={{ borderBottom: 1 , borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" >
            <Tab label="الفـكـــرة || The idea " value="idea" />
            <Tab label="الـموقـــع || The site" value="site" />
            <Tab label="المحــــتوى || The content" value="content" />
            <Tab label="صنًاع المحتوى || Our creators " value="creators" />
            <Tab label="العروض الحالية || Current offers  " value="offers" />
            <Tab label="تواصل معنا || Contact us" value="contact" />

          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
}