
// components
import {Typography , Stack, Container , Box , Card , CardMedia , CardActionArea , CardContent} from '@mui/material';

import Page from '../../components/Page';
import SiteSlider from './Siteslider';
import WalletSlider from './Walletslider';

import MainTabHelp from './Maintabhelp';
import img from './osoul-idea.png'
import imgwallet from './contentsliders/wallet.jpg'
// ----------------------------------------------------------------------


export default function Site() {




  return (
    <Page title="Help Center"  >
            <Stack  sx={{ direction:'rtl'}}>
            <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1,  border: 1 , borderColor: 'divider'}}>
              <Box >
                    <CardMedia
                      component="img"
                      image={img}
                      alt="Registerion and login"
                    />
                    </Box>
                <Box sx={{pr:2 }} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }} >إنشاء حساب وتفعيله وتسجيل الدخول</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1 }}>  من خلال صفحة <a>Registerition</a> يمكنك انشاء حسابك  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> فقط قم بكتابة اسمك وعنوان بريدك الالكتروني واختار كلمة السر التي تريدها ثم اعد كتابتها للتأكد من صحتها </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> بعدها تستطيع تسجيل الدخول في اي وقت من خلال صفحة login بإستخدام عنوان بريدك وكلمة السر التي اخترتها </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> لن يتم ارسال رسالة تأكيد البريد الالكتروني الا بناء علي طلبك ،، وستجد رابط confirm mail في صفحتك الشخصية  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>نود التذكير بأننا لن نقوم بمشاركة بياناتك مع اي جهة اخري تحت اي مسمي ، كما يمكنك مراجعة سياسة خصوصية البيانات الخاصة بنا من خلال الرابط عند التسجيل او من هنا  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> صٌممت واجهة الموقع باللغة الانجليزية لكنها بسيطه جدا وبعيدة عن التعقيد ، واليك فيما يلي توضيح لأهم الصفحات ونظام الدفع والشراء علي الموقع </Typography>


                </Box>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 ,my:1,  border: 1 , borderColor: 'divider'}}>
              <Box sx={{pl:2 }} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}> أهم صفحات الموقع </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>الصفحة الرئيسية وتحتوي في الجزء الجانبي منها قائمة جانبية تظهر فقط عند استخدام الموقع من جهاز الكمبيوتر وبها شعار الموقع واختصار للملف الشخصي للمستخدم وقائمة بأقسام الموقع الرئيسية وفي الجزء العلوي يوجد مربع البحث ويمكنك من البحث داخل المحتوي الموجود علي الموقع بسهولة ايضا يوجد بها رابط للوصول الي ملفك الشخصي ومحفظتك ومركز الدعم والمساعدة  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> صفحة تفاصيل الملف ومنها يمكنك مشاهدة صورة الملف بشكل اوضح واكبر ومعرفة المزيد من التفاصيل والاحصائيات حول الملف كما يمكنك تحميله او حفظه لملفاتك المفضلة او الابلاغ عنه بسهولة </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> صفحة الملف الشخصي ، وهي خاصة بك ستجد بها معلوماتك الاساسية التي قمت بتقديمها والتي يمكنك تعديلها او حذفها كما يمكنك الانتقال من خلالها الي الصفحه الخاصة بمحفظتك او الخاصة بملفاتك المفضله او تحميلاتك علي الموقع  </Typography>

                </Box>
                <SiteSlider />

              </Stack>
              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1, border: 1 , borderColor: 'divider'}}>
              <Box >
                    <CardMedia
                      component="img"
                      image={imgwallet}
                      alt="green iguana"
                    />
                    </Box>
                    <Box sx={{pr:2 }} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}>رصيد محفظتي </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> عند انشاء حساب جديد علي موقعنا يتم انشاء محفظة خاصة بالحساب وبعد اتمام خطوة تأكيد البريد يتم اضافة 25 كوينز رصيد مجاني الي محفظتك تمكنك من تحميل علي الاقل ملف واحد للتجربة   </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> اما الكوينز - coins - فهي عملة افتراضية خاصة بالموقع يتم تحديد سعر الملفات بناء علي اهمية الملف بها </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>عند شحن المحفظة تجد رصيدك بوضوح في صفحتك الشخصية وعند محاولة تحميل اي ملف يظهر لك رسالة تأكيد بها تكلفة الملف ورصيد محفظتك والرصيد المتبقي بعد التحميل ويمكنك الرفض او الموافقه واستكمال التحميل  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>يمكنك شحن محفظتك بسهولة من خلال بطاقات الدفع البنكية او من المحافظ الالكترونية او عن طريق باي بال   </Typography>


                </Box>
              </Stack>


              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1, border: 1 , borderColor: 'divider'}}>
              <Box sx={{pl:2 }} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}>اسلوب تسعير الملفات  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> يتم تسعير الملف بناء علي حجم وجودة العمل داخل الملف او الهدف منه </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>فمثلا لايمكن مساواة تصميم كارت شخصي مثلا بتصميم هوية كاملة ، أيضا لايمكن مساواة تصميم بسيط بتصميم معقد وكثير العناصر  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> وعلي الارجح تكون اسعار الملفات مابين 10 كوينز الي 55 كوينز  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> سعر كل ملف موضح عليه يمكنك معرفته بمجرد النظر الي الصورة المصغرة او عند فتح صفحة تفاصيل الملف ، وايضا عند محاولة تحميل اي ملف تظهر رسالة للتأكيد من رغبتك في عملية التحميل وتوضح لك رصيدك قبل وبعد تنزيل الملف</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> من الامور الهام جدا توضيحها ايضا انه عند تحميل ملف معين من الموقع ينتقل الي صفحة خاصة بتحميلاتك ، ومنها يمكنك تحميله في اي وقت عدد لانهائي من المرات بدون خصم رصيد من محفظتك .</Typography>


                </Box>
                <WalletSlider/>

              </Stack>


              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1, border: 1 , borderColor: 'divider'}}>
              <Box sx={{pl:2 }} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }} > باقات الشحن  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> باقة الـ 5 دولارات : وهي اقل قيمة يمكنك شحنها وتعطيك 200 كوينز  تمكنك من تحميل حتي 4 ملفات مختلفة وهي مخصصة لمن يريد ملف واحد او اثنان ولا يريد اكثر من ذلك حتي في المستقبل   </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> باقة الـ 10 دولارات : وتضيف الي محفظتك 1000 كوينز رصيد يمكنك استخدامها في تحميل مايقارب 30 ملف مختلف من المحتوي   </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> باقة الـ 25 دولار : وتضيف الي محفظتك 5000 كوينز رصيد يمكنك استخدامها في تحميل مايقارب 150 ملف مختلف من المحتوي   </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> باقة الـ 50 دولار : وتضيف الي محفظتك 20000 كوينز رصيد يمكنك استخدامها في تحميل مايقارب 600 ملف مختلف من المحتوي   </Typography>

                </Box>
              </Stack>

            </Stack>
    </Page>
  );
}
