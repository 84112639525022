import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import pic from './mail2.png';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '80%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function PlsConfirm() {
  return (
    <Page title="PlsConfirm">
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center'}}>
       
        
          <Box sx={{maxWidth:'350px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color: 'black' , fontSize:'1.2rem' , fontWeight:'600'}}>
            We have send a confirmation message to your email <br/> Please go to your mail inbok and follow the link <br/> ----------------------
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
    </Page>
  );
}
