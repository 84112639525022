import React from 'react';
// -----
import './Filesumb.css';
import { Typography, Stack, Box, Avatar, Rating } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Fingerprint from '@mui/icons-material/Fingerprint'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';









const OD = ({file}) => {

  if (file.owner?.username)
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ padding: '0px 5px' }}>
        <Box flex={1} sx={{ paddingRight: { xs: '3px', lg: '0px' } }}>
          <a href="">
            <Avatar alt="" src={file.owner.profileimage} />
          </a>
        </Box>
        <Box flex={7} sx={{
            paddingLeft: '2px',
            paddingTop: ' 2px',
            color: 'rgb(2, 0, 0)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '20rem',
          }}>
          <Typography variant="h6"  style={{ textTransform: 'capitalize' }}>
            {file.owner.username}{' '} <VerifiedUserIcon sx={{fontSize:'medium', color:'rgb(0, 131, 120)'}}/>
          </Typography>
          <Stack direction="row" justifyContent="space-evenly">
            
            <Typography variant="subtitle1" sx={{}}>Artist creator</Typography>
          
             <Rating name="size-medium" defaultValue={4} />
             <IconButton aria-label="fingerprint" color="success" sx={{}}>
              <Fingerprint />
            </IconButton>
        
          </Stack>
        </Box>
      </Stack>
    );
};

export default OD;
