import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../../components/Page';
import pic from './thankyou.JPG';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({

  boxShadow: '10px 20px 20px -3px rgba(0, 0, 0, .2), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',

  maxWidth: '95%',
  maxHeight:"65vh",
  margin: 'auto',
  marginTop : '50px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

const GetButton = styled('button')({
  boxShadow: '0px 3px 2px rgb(204, 204, 204)',
  border: 'none',
  textAlign: 'center',
  backgroundColor: 'rgb(0, 125, 125)', // Original color
  '&:hover': {
    backgroundColor: 'rgb(0, 100, 100)', // Darker color on hover
  },
});

// ----------------------------------------------------------------------

export default function ThankYou() {

  const [data, setData] = useState(0);
  const [message, setMessage] = useState('You order has succesfully complete ,, You must find your coins in your wallet.');


  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };


  const navigate = useNavigate();


  const handleClick1 = () => {
    navigate('/Graphic-Stock');
  };

  const handleClick2 = () => {
    navigate('/dashboard/wallet');
  };
/*
  useEffect(() => {
    axios.post(
      `${process.env.REACT_APP_API_KEY}/api/v1/accounts/process-user-order/`,
      config
    )
      .then(response => {
        setData(response.data);
        setMessage(response.data.message);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);
console.log(data) 
*/
  return (
    <Page title="thank you">
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' , backgroundColor:'white'}}>
        
          <Box sx={{maxWidth:'750px'}}>
                <img src={pic} alt='' />
          </Box>
          
          <Typography sx={{ color:"rgb(35, 35, 35)" , fontSize:'1.2rem' , fontWeight:'600'}}>
            {message} 
             <br/>
            
          </Typography>

          <Box  justifyContent="space-between" >
            <GetButton sx={{minWidth:'33%' , px:3 ,fontSize:'1.3rem' , bgcolor:'rgb(0, 125, 125)' , borderRadius:.7, m: 1}} onClick={handleClick1} >
              <Typography sx={{py:1, color:'white' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >go to home page</Typography>
            </GetButton>
            <GetButton sx={{minWidth:'33%' , px:3 ,fontSize:'1.3rem' , bgcolor:'rgb(0, 125, 125)' , borderRadius:.7 , m: 1}} onClick={handleClick2} >
              <Typography sx={{py:1, color:'white' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >go to my wallet</Typography>
            </GetButton>
          </Box>


        </ContentStyle>
    </Page>
  );
}

