
import {Box, Typography } from '@mui/material';
// components
import pic from './osoul last offer.JPG';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function SquareAd() {
  return (
     <Box >
      <a href="/help-center/offers/" >
       <img src={pic} alt='It`s a special offer' />
       </a>
     </Box>

  );
}
 /*
<Typography textAlign="center"  fontSize="1.7rem" color='green'> Special Offer</Typography>
<Typography fontSize="1.8rem" color='gray' > Only by 9.99 $ Get 5000 coins</Typography>
<Stack sx={{width:"100%" , alignItems:'center' , my:4 , bgcolor:'#006673'}}>
<Stack sx={{direction:'row',alignItems:'center' , width:"70%" , borderBottom:2 , borderTop:2 , borderColor:'cyan'}}>
<Typography display='flex' variant='h2' sx={{color:'white' , fontWeight:"600"}} >5000 <Iconify style={{ color: 'white' }} icon="ph:coins-light" className="icon" width={30} height={30} marginTop={1} /></Typography>

</Stack>
</Stack >
<Stack sx={{width:"100%" , alignItems:'center' , my:2 , bgcolor:'#f1f1f1' , borderBottom: 1, borderTop: 1 , borderColor: '#006673' }}>
  <Box sx={{direction:'row'}}>
<Typography display='flex' variant='h2' sx={{color:'green'}} >25<Typography variant='h5' > $</Typography></Typography>
</Box>
</Stack > */