import { useEffect, useState } from 'react';
import * as React from 'react';
import axios from 'axios';


import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';


import Message from '../../Form/Message';
import img from './sender.jpeg'










export default function NotificationsList(config) {
const [notifications , setNotifications] = useState([]);
const [messageid, setMessageid] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);



  const getNotifications = async () => {
    try {

    const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/accounts/notifications/`, config);
    setNotifications(response.data);
    } catch (err) {
      console.error(err);
    }
};
// -----*-----*------*------*-----*-----*-----*-----*-----*-----
useEffect(() => {
  getNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])



  return (
    <>
      <CssBaseline />
      <Paper square sx={{ pb: '50px' }}>
        <Typography variant="h5" gutterBottom component="div" sx={{ p: 2, pb: 0 }}>
          Messages & Notifications .
        </Typography>
        <List sx={{ mb: 2 , overflowY:'scroll' , maxHeight:'400px' }}>
          
        {notifications.map((notification , index) => (
        <React.Fragment key={index}>
          <ListItem button onClick={() => { setMessageid(notification.id); setShow(true); }}  >
                <ListItemAvatar>

                  <Avatar alt="Profile Picture" src={img}  />
                </ListItemAvatar>
                <ListItemText key={notification.id} primary={notification.title} secondary={notification.message} />

                </ListItem>
          {messageid === notification.id && (
            <Message
              id={notification.id}
              handleClose={() => { setMessageid(null); setShow(false); }}
              show={show}
            />
          )}
        </React.Fragment>
      ))}
        </List>
     
      </Paper>
   
    </>
  );
}