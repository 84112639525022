import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack , IconButton , Badge  } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

import axios from 'axios';
import { useSelector } from 'react-redux';
import logo from './Advertising_agency.webp';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import CreatorGroupBtn from '../../components/Btns/CreatorBtn/CreatorGroupBtn';

//
import navConfig from './NavConfig';
import navConfig2 from './NavConfig2';
import navConfig0 from './NavConfig0';


import img from './save-palastine.webp'

// ----------------------------------------------------------------------


const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const [userData, setUserData] = useState({});
  const BlankPofile = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png';
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // -----------------------------------
  // Data after sending the token Validation__Authorization
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  //  getting sub_User_Data Function
  const getUserData = async () => {
    if (userInfo) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo.id}`,
        config
      );
      setUserData(response.data);
    } 
  };
  useEffect(() => {
    getUserData();
  },[]);

  const RenderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >

      <Box sx={{ px:3, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 0, mx: 2.5  }}>
        <Link underline="none" component={RouterLink} to={userInfo ? '/dashboard/profile' : '/login'}>
          <AccountStyle>
            <Avatar
              src={userInfo ? `${process.env.REACT_APP_API_KEY}${userData?.avatarUrl}` : BlankPofile}
              alt="photoURL"
            />
            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userInfo ? <>{userInfo.name}</> : 'Unkown vistor'}
                      <IconButton aria-label={('100')}>
                        <Badge badgeContent={1} color="error"  sx={{maxWidth:'5px', maxHeight:'5px'}}>
                          <MailIcon />
                        </Badge>
                      </IconButton>
              </Typography>

            </Box>
          </AccountStyle>

        </Link>
      </Box>
      <NavSection navConfig={navConfig0} />
      <NavSection navConfig={navConfig} />
      <NavSection navConfig={navConfig2} />


      <Box sx={{ flexGrow: 1 }} />

      <Box >

        <Stack alignItems="center" spacing={3} sx={{ pt: 0, borderRadius: 2, position: 'relative' }}>
            <Box sx={{  p:2  }} > 
            <img src={img}   alt='' /> 
            </Box>
        </Stack>
      </Box>

    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {RenderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {RenderContent}
          <Box component={RouterLink} to={'/files/creators'} sx={{ color: '#212529', textDecoration:'none' }}>
            
         {/* <CreatorGroupBtn userData={userData} /> */}
         </Box>
        </Drawer>
      )}
    </RootStyle>
  );
}
