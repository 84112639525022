import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
// components
import Page from '../../components/Page';
import GalleryCard from '../../components/GalleryCard';
import Onesub from './Onesub'; 
import Loader from '../../components/loader/Loadmore';

const InfiniteScroll = lazy(() => import('react-infinite-scroll-component'));
const Grid = lazy(() => import('@mui/material/Grid'));
const {ImageList , Stack , Card , ImageListItem} = lazy(() => import('@mui/material'));
const Box = lazy(() => import('@mui/material/Box'));

// ----------------------------------------------------------------------
export default function AllSubCat() {
  const [subcat, setSubcat] = useState([]);
  const [page, setPage] = useState (2);
  const [more, sethasMore] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = useMemo(() => ({
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  }), [userInfo?.access]);
  
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  // a function to get the initial subcategories
  const getSubcat = async () => {
    try {
      // use the config if the user is logged in
      const response = userInfo ? await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/subcategorys/`, config) : await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/subcategorys/`);
      // set the subcategories state with the response data
      setSubcat(response.data.results);
    } catch (error) {
      // handle any errors
      console.error(error);
    }
  };

  // a function to fetch more subcategories
  const fetchSubcat = async () => {
    try {
      // get the next page of subcategories
      const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/subcategorys/?page=${page}`);
      // return the response data
      return res.data.results;
    } catch (error) {
      // handle any errors
      console.error(error);
    }
  };

  // a function to fetch data when scrolling
  const fetchData = async () => {
    try {
      // get the new subcategories
      const newSubcat = await fetchSubcat();
      // append them to the existing subcategories
      setSubcat([...subcat, ...newSubcat]);
      // check if there are more subcategories to load
      if (newSubcat.length === 0 || newSubcat.length < 25) {
        sethasMore(false);
      }
      // increment the page number
      setPage(page + 1);
    } catch (error) {
      // handle any errors
      console.error(error);
    }
  };

  // use effect hook to get the initial subcategories on mount
  useEffect(() => {
    getSubcat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title='all subcategory'>
      <Suspense fallback={<Loader />}>
        <InfiniteScroll
          dataLength={subcat.length} // This is important field to render the next data
          next={fetchData}
          hasMore={more}
          loader={<Loader />}
          endMessage={
            ""
          } 
        >
          <Grid container spacing={1} >
            {subcat.map((subcat) => (
              // use a unique key for each item
              <Grid item key={subcat.id}>
                <Onesub  subcat={subcat} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </Suspense>
    </Page>
  );
}