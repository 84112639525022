import React, { useState, useEffect } from 'react';
import { Box ,Typography , Stack ,ImageList , ImageListItem, Hidden } from '@mui/material';


import axios from 'axios';
import { useSelector } from 'react-redux';

import '../Maingallery.css';


// material
// components
// ----------------------------------------------------------------------
export default function SubCatCard({subcat, index }) {

  const [subcattitle , setSubcattitle] = useState([subcat.title]);
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState ([subcat.id]);
  const [more, sethasMore] = useState(true);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // console.log(subcattitle);



  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  const getFiles = async () => {
    if (userInfo) {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-subcategory/${page}/`, config);
  

      setFiles(response.data.results);
    } else {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-subcategory/${page}/`);
      setFiles(response.data.results);
    }
  };
 
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (<>
                 

                        <ImageList sx={{ width: 200, height: 200 ,overflow:'hidden', p:.5 }} cols={2} rowHeight={55} key={index} >
                        {files.map((file, index) => (
                          <ImageListItem key={file.id} >
                            <img
                            //  src={`${file.thumbnail}?w=164&h=164&fit=crop&auto=format`}
                              srcSet={`${file.thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              alt={''}
                              loading="lazy"
                            />
                          </ImageListItem>
                            ))}
                      </ImageList>
              
                
                            
                            </>       

   
  );
}

const item = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
      featured: true,
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
 
]