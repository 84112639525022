import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Typography , Alert , Button , Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';



const messages = [
{
id: 3,
primary: 'Recipe to try',
secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
person: '/static/images/avatar/2.jpg',
}]

const DownloadButton = styled('button')({
    backgroundColor: '#13aa52',
    border: '1px solid #13aa52',
    borderRadius: '4px',
    boxShadow: 'rgba(0, 0, 0, .1) 0 2px 4px 0',
    boxSizing: 'border-box',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '400',
    outline: 'none',
    padding: '7px 25px',
    textAlign: 'center',
    transform: 'translateY(0)',
    transition: 'transform 150ms, box-shadow 150ms',
    userSelect: 'none',
    touchaction: 'manipulation',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
      transform: 'translateY(-2px)',
    },
    '&:focus': {
      boxShadow: 'rgba(0, 0, 0, .15) 0 3px 9px 0',
      transform: 'translateY(-2px)',
    },
  });


// -------------------------------------------

// -------------------------------------------

export default function Message({ handleClose, show, fileId, fileTitle, filePrice, userCoins , userverified , id }) {
  const Navigate = useNavigate();

  const [message , setMessage] = useState({});

  // ----------------------------------------------------
  const [balanceColor, setbalanceColor] = useState('yellowgreen');
  // -----------------------------------------------------
  // -----------------------------------------------------
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };



  
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/accounts/notifications/${id}/`, config);
        setMessage(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [show,id, config]);
  



  const balanceAfter = userCoins - filePrice
  // ----------------------------------------



  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg" margin='2rem' >
        <Modal.Header closeButton >
          <Avatar alt="Profile Picture" sx={{mr:1}}  />
          <ListItemText primary={message.title} secondary={new Date(message.sent_at).toLocaleString()} />
        </Modal.Header>
        <Modal.Body>
          <Box sx={{ textAlign: 'center' }}>
            {message.image && ( <img src={message.image} alt="Message" style={{ maxWidth: '100%' }} /> )}
            <Typography sx={{p:1 , color:'red'}}>{message.message}</Typography>
            {message.url && (
              <Button variant="outlined" color="primary" onClick={() => window.open(message.url, "_blank")}>
               read more
              </Button>
            )}
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}
