import React, { useState, useEffect } from "react";

const UserScope = () => {
  const [userscopes, setUserscopes] = useState([]);

  useEffect(() => {
    // get the value of names from localStorage
    const storedScopes = localStorage.getItem("userscopes");
    // if there is a value in localStorage, update the state
    if (storedScopes) {
        setUserscopes(JSON.parse(storedScopes));
    }
  }, []);

  useEffect(() => {
    // set the value of names in localStorage
    localStorage.setItem("userscopes", JSON.stringify(userscopes));
  }, [userscopes]);


};

export default UserScope;