import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import jwtDecode from "jwt-decode";
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';

import '../Maingallery.css';
import Page from '../../components/Page';
import Loader from '../../components/loader/Loadmore';
import GalleryCard from '../../components/GalleryCard';
import { logout } from '../../redux/actions/authActions';

// Define a helper function to shuffle an array
const shuffleArray = (array) => {
  const copy = [...array];
          // eslint-disable-next-line no-plusplus
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copy[i], copy[j]] = [copy[j], copy[i]];
  }
  return copy;
};

export default function ScopePage() {
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [more, sethasMore] = useState(true);
  const { id } = useParams();
  const { str } = useParams();
  const [columns, setColumns] = useState(1);


  const matchesXS = useMediaQuery('(max-width:600px)');
  const matchesSM = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const matchesMD = useMediaQuery('(min-width:960px) and (max-width:1280px)');
  const matchesLG = useMediaQuery('(min-width:1280px) and (max-width:1600px)');
  const matchesXL = useMediaQuery('(min-width:1600px)');
  
  


    // Check screen size and set columns accordingly
    useEffect(() => {
      if (matchesSM) {
        setColumns(2);
      } else if (matchesMD) {
        setColumns(3);
      } else if (matchesLG) {
        setColumns(3);
      } else if (matchesXL) {
        setColumns(4);
      } else {
        setColumns(1);
      }
    }, [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]);

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  // Function to get the initial files from the API
  const getFiles = async () => {
    let response;
    if (userInfo) {
      if (jwtDecode(userInfo.access).exp < Date.now() / 1000) {
        dispatch(logout());
        window.location("/login");
      }
      response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/scopes/file-scopes/${id}/?page=${page}`, config);
    } else {
      response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/scopes/file-scopes/${id}/?page=${page}`);
    }
    setFiles(shuffleArray(response.data.results)); 
    if (response.data.results.length === 0 || response.data.results.length < 25) {
      sethasMore(false);
    }
    setPage(page + 1);
  };

  useEffect(() => {
    getFiles();
  }, []);

  // Function to fetch more files from the API based on the page number
  const fetchFiles = async () => {
    let res;
    if (userInfo) {
      if (jwtDecode(userInfo.access).exp < Date.now() / 1000) {
        dispatch(logout());
        window.location("/login");
      }
      res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/scopes/file-scopes/${id}/?page=${page}`, config);
    } else {
      res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/scopes/file-scopes/${id}/?page=${page}`);
    }
    return (shuffleArray(res.data.results));
  };

  // Function to fetch more data and update the state
  const fetchData = async () => {
    const newFiles = await fetchFiles();
    setFiles([...files, ...newFiles]);
    if (newFiles.length === 0 || newFiles.length < 25) {
      sethasMore(false);
    }
    setPage(page + 1);
  };



  return (
    <Page title={str}>
      <InfiniteScroll
        dataLength={files.length} 
        next={fetchData}
        hasMore={more}
        loader={<Loader />}
        endMessage={""} 
      >
        <Masonry columns={columns} spacing={3.5}>
          {files.map((file, index) => (
            <GalleryCard lovelist key={file.file.id} file={file.file} index={index} />
          ))}
        </Masonry>
      </InfiniteScroll>
    </Page>
  );
}
