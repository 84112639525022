import React from 'react';
import { Skeleton } from '@mui/material';
import Filesumb from './Filesumb';

const FileDetail = ({file}) => {

  const [imgLoaded, setImgLoaded] = React.useState(false);

  return (
    <div style={{ position: 'relative', paddingBottom: '60px' }}>
      {!imgLoaded && <Skeleton variant="rect" width={file.image_width} height={file.image_height} />}
      <img src={file.image} style={{ width: '100%' }} alt={file.title} onLoad={() => setImgLoaded(true)} hidden={!imgLoaded} />
      <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
        <Filesumb file={file} />
      </div>
    </div>
  );
};

export default FileDetail;
