
// components
import {Typography , Stack, Container , Box , Card , CardMedia , CardActionArea , CardContent} from '@mui/material';

import Page from '../../components/Page';
import IdeaSlider from './Ideaslider';
import MainTabHelp from './Maintabhelp';
import img from './osoul-idea.JPG'
// ----------------------------------------------------------------------


export default function ContactUS() {




  return (
    <Page title="Help Center" >
            <Stack sx={{p:1 , direction:'rtl'}}>
            <Stack sx={{width:"100%"  , alignItems:'center', minHeight:50, bgcolor:'orange'}}>
              <Box >
                <Typography variant='h5' direction="rtl" sx={{pt:1}}>واتس اب - (002)01124882882 !</Typography>
              </Box>
            

              </Stack>
              </Stack>

         
    </Page>
  );
}
