// material
import { Grid, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import CreatorCardInfo from './CreatorCardInfo';
import CreatorBtn from '../../../components/Btns/CreatorBtn/CreatorBtn';
// mock
import POSTS from '../../../_mock/blog';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function AllCreatorsPage() {
  return (
    <Page title="Creators">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Creators
          </Typography>
          <CreatorBtn btnContent={'Join Now'} path={'/dashboard/creatorprofile'} />
        </Stack>

        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}

        <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <CreatorCardInfo key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
