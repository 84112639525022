import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';

import InfiniteScroll from 'react-infinite-scroll-component';
import jwtDecode from 'jwt-decode';
import { logout } from '../redux/actions/authActions';

import GalleryCard from './GalleryCard';
import '../pages/Maingallery.css';
import Loader from './loader/Loadmore';

export default function RelatedGallery() {
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [columns, setColumns] = useState(1);

  const matchesXS = useMediaQuery('(max-width:600px)');
  const matchesSM = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const matchesMD = useMediaQuery('(min-width:960px) and (max-width:1280px)');
  const matchesLG = useMediaQuery('(min-width:1280px) and (max-width:1600px)');
  const matchesXL = useMediaQuery('(min-width:1600px)');

  useEffect(() => {
    if (matchesSM) {
      setColumns(2);
    } else if (matchesMD) {
      setColumns(3);
    } else if (matchesLG) {
      setColumns(3);
    } else if (matchesXL) {
      setColumns(4);
    } else {
      setColumns(1);
    }
  }, [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]);

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  const fetchRelated = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-more-related/${id}/?page=${page}`,
      userInfo ? config : null
    );

    return res.data.results;
  };

  const fetchMoreRelated = async () => {
    const newFiles = await fetchRelated();

    setFiles([...files, ...newFiles]);
    if (newFiles.length === 0 || newFiles.length < 25) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  useEffect(() => {
    fetchMoreRelated();
  }, [id]); // Add id as a dependency

  return (
    <>
      <InfiniteScroll
        dataLength={files.length}
        next={fetchMoreRelated}
        hasMore={hasMore}
        loader={<Loader />}
        endMessage={''}
      >
        <Masonry columns={columns} spacing={3.5}  sx={{pl:4 , pt:2}} >
          {files.map((file, index) => (
            <GalleryCard lovelist key={file.id} file={file} index={index} />
          ))}
        </Masonry>
      </InfiniteScroll>
    </>
  );
}
