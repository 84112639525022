import {useDispatch, useSelector } from 'react-redux';
import jwtDecode from "jwt-decode";
// import {Alert} from '@mui/material' ; 

import { logout } from './redux/actions/authActions';




export default function IsvaledToken() {

    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

if (userInfo) {
          if (jwtDecode(userInfo.access).exp < Date.now() / 1000) {
            dispatch(logout());
         //   return (
           //     <Alert>"For more acoount security ,,, we have logedout your account and you must login again "</Alert>
             // );
           //   window.location.replace('/login');
         //  window.alert("For more acoount security ,,, we have logedout your account and you must login again ");
          }

     
}
}