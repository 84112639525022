
// components
import {Typography , Stack, Box ,Container} from '@mui/material';

import Page from '../../components/Page';
import MainTabHelp from './Maintabhelp';
// ----------------------------------------------------------------------


export default function HelpCenter() {




  return (
    <Page title="Help Center" >
            <Stack sx={{p:3}}>
            <MainTabHelp />
            </Stack>
          
    </Page>
  );
}
