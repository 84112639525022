import * as React from 'react';
import { useParams , useNavigate } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function ScrollableTabsButtonAuto() {

const { str } = useParams();

  const [value, setValue] = React.useState(str);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth:{ xs: 370, sm: 480 , md:700, lg:1200}, bgcolor: 'paper'}} >
      <Tabs
        sx={{px:{xs:.2 , sm:.5 , md:2 , lg:5 , color:'#006673'}}}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons='auto'
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs"
      >
            <Tab label="The idea || الفـكـــرة  " value="idea"  href='/help-center/idea'/>
            <Tab label="The site || الـموقـــع  " value="site" href='/help-center/site'/>
            <Tab label="The content || المحــــتوى  " value="content" href='/help-center/content' />
            <Tab label=" Our creators || صنًاع المحتوى  " value="creators" href='/help-center/creators' />
            <Tab label="Current offers || العروض الحالية    " value="offers" href='/help-center/offers' />
            <Tab label="Contact us || تواصل معنا  " value="contact" href='/help-center/contact' />

      </Tabs>
    </Box>
  );
}