// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// state:'disabled' for disabled link
// state:'block' for visable & clickable link

const navConfig = [
 // {
   // title: 'home page',
   // path: '/Graphic-Stock',
   // icon: getIcon('eva:home-outline'),
   // state: 'block',
 // },

  {
    title: 'psd templates',
    path: '/files/psd-template',
    icon: getIcon('eva:layers-outline'),
    state: 'block',
  },
  {
    title: 'designer bag',
    path: '/files/designer-bag',
    icon: getIcon('eva:briefcase-outline'),
    state: 'block',
  },
  {
    title: 'full wall paper',
    path: '/files/full-wall-paper',
    icon: getIcon('eva:image-outline'),
    state: 'block',
  },
  {
    title: 'wall decoration',
    path: '/files/wall-decoration',
    icon: getIcon('eva:speaker-outline'),
    state: 'block',
  },


  {
    title: 'laser art cut',
    path: '/files/laser-art-cut',
    icon: getIcon('eva:flash-outline'),
    state: 'block',
  },

  // {
  //   title: 'Graphic artists',
  //   path: '#',
  //   icon: getIcon('eva:color-palette-outline'),
  //   state: 'block',
  // },
];

export default navConfig;
