import React, { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { Stack, Box, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import Iconify from '../Iconify';
import img from './offer.jpg'





const GetButton = styled('button')({
    boxShadow: '0px 5px 2px rgb(204, 204, 204)',
    border: 'none',
    textAlign: 'center',
  });




export default function Card10() {
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = React.useState(false);



  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  //  getting sub_User_Data Function
  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
        config
      );
      setUserData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBundelsData = async () => {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo?.access}`,
        },
      };
      setLoading(true);
      const url = `${process.env.REACT_APP_API_KEY}/api/v1/files/deposit-kashier/2/`;
      const Body = {};
      axios
        .post(url, Body, config)
        .then((result) => {
          const responseUrl = result.data.redirect_iframe_url;
          setLoading(false);
          window.location.replace(responseUrl);
        
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(true);
    };
  
  

  return (
    <Card sx={{ maxWidth: 275 , border:1  , borderRadius:1.5  ,boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0' , mt:1 , "&:hover": {
      boxShadow:'none', background: '#fffdf3',
      cursor:'pointer',
        },}}>
               {/*     <Box component="img" src={img} sx={{pt:1}} /> */} 

      <Stack sx={{width:"100%" , alignItems:'center' , my:4 , bgcolor:'#006673'}}>
      <Stack sx={{direction:'row',alignItems:'center' , width:"70%" , borderBottom:2 , borderTop:2 , borderColor:'cyan'}}>
      <Typography display='flex' variant='h2' sx={{color:'white' , fontWeight:"600"}} >1000 <Iconify style={{ color: 'white' }} icon="ph:coins-light" className="icon" width={30} height={30} marginTop={1} /></Typography>

      </Stack>
      </Stack >
      <Stack sx={{width:"100%" , alignItems:'center' , my:2 , bgcolor:'#f1f1f1' , borderBottom: 1, borderTop: 1 , borderColor: '#006673' }}>
        <Box sx={{direction:'row'}}>
      <Typography display='flex' variant='h2' sx={{color:'green'}} >10<Typography variant='h5' > $</Typography></Typography>
      </Box>
      </Stack >
   
      <Box sx={{py:4 , px:2 }}>
      <Typography variant="body2" color="text.secondary">
         <DownloadDoneIcon sx={{width:20 , height:20, color:"green"}} />Pay: 10 Dollars .
        </Typography>
        <Typography variant="body2" color="text.secondary">
         <DownloadDoneIcon sx={{width:20 , height:20, color:"green"}} />Get  : 1000 coins .
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <DownloadDoneIcon sx={{width:20 , height:20, color:"green"}}/>Download average :40 file .
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <DownloadDoneIcon sx={{width:20 , height:20 , color:"green"}} />Coins EXP : Valid for a life .
        </Typography>
      </Box >
      <Stack sx={{width:"100%" , alignItems:'center' , mb:5 , bgcolor:'#f8f9fa'}}>
      {loading ? (
                  <CircularProgress sx={{width:'1rem', height:'1rem', color:'#006673'}}/>
              ) : (
      <GetButton sx={{width:170  , bgcolor:'#006673' , borderRadius:.7}} onClick={() => getBundelsData()}>
        <Typography sx={{py:1, color:'cyan' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >Charge Now</Typography>
        </GetButton>
         )}
      </Stack>
     
    </Card>
  );
}