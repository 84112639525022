
// components
import {Typography , Stack, Container , Box , Card , CardMedia , CardActionArea , CardContent} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import Page from '../../components/Page';
import IdeaSlider from './Ideaslider';
import MainTabHelp from './Maintabhelp';
import img from './idea.png'
import img1 from './Graphic_Stock_osoul.webp'



// ----------------------------------------------------------------------


export default function Idea() {




  return (
    <Page title="Help Center" >
            <Stack sx={{ direction:'rtl'}}>
            <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1,  border: 1 , borderColor: 'divider'}}>
              <Box> 
                {img ? (
                    <CardMedia
                      component="img"
                      image={img}
                      alt="green iguana"
                    />
                    ):(
                      <Skeleton variant="rectangular" width={210} height={118} />

                    )};
                    </Box>
                <Box sx={{pr:4}} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}>ماهي الفكرة من اصول جرافيك ستوك ؟</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>كل تصميم او عمل فني هو عبارة عن درس من منهج خاص بمصمم ما ، وبغض النظر عن كم المعلومات المستفاد من ذلك الدرس إلا ان بداخل كل درس فكرة ،،، بل ربما أفكار . </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> فبمجرد تجميع عدد كبير من الدروس - التصميمات - بمختلف المناهج - المصممين - يمكننا صنع مدرسة حقيقية  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>وهذا ماحدث عندما حاولنا تجميع عدد كبير من التصميمات التي تخدم المجال الاعلاني ، اذ اصبح لدينا موسوعة كبيرة من الدروس المتنوعة والتي ادي اختلاف مصمميها الي تحويلها الي مكتبة عملاقة من المعلومات والافكار  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> وكما استفدنا كثيرا من كل تلك المعلومات والافكار اردنا ايضا ان نشاركها معكم فهنا ستجد لكل و لـ أي عمل من الممكن ان تقوم به في اعمال الدعاية والاعلان العديد من التصميمات والافكار ،، او بمعني أكثر وصفاً ستجد لكل سؤال في امتحانات اعمالك إجابة نموذجية يمكنك الاستعانة بها . </Typography>
                </Box>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1,  border: 1 , borderColor: 'divider'}}>
                <Box sx={{pl:4}} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}> لماذا اصول جرافيك ستوك ؟</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>$ لأنها اكبر موسوعة عربية للتصميمات التجارية والقوالب الجاهزة والمعدة للتعديل ، والادوات والملحقات والعديد من الملفات الاخري التي يحتاج اليها كل من يعمل بمجال الدعاية والاعلان أو الطباعة او التصميم الجرافيكي . </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>$ هنا الاف التصميمات التجارية - مفتوحة المصدر - ذات الطابع الشرقي والعربي والتي يمكنك تحميلها وتعديلها في دقائق وتساعدك علي انجاز اكبر عدد احترافي من التصميمات في اقل وقت .  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> $ هنا أيضاالالاف من افكار التصميمات الجديدة والمختلفة والتي شارك في ابتكارها العديد من محترفي التصميم الجرافيكي والتي تسهل عليك مهمة الحصول علي فكرة لعملك او مجموعة الوان متناسقة لتصميمك .  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> $ ايضا الاف من الملحقات والادوات والخطوط والصور وملفات الفيكتور والقوالب التي جَمًعت بعناية وخصصت لخدمة جميع العاملين بمجال الدعاية والاعلان  </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> $ والالاف من تصميمات التابلوهات العربيه والاسلامية الحديثة وتصميمات اوراق الجدران ومكتبة متميزة لأجمل تصميمات واعمال الليزر  </Typography>
                </Box>
                <IdeaSlider />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} sx={{p:1 , my:1,  border: 1 , borderColor: 'divider'}}>
              <Box maxWidth={300} > 
                {img ? (
                    <CardMedia
                      component="img"
                      image={img1}
                      alt="green iguana"
                    />
                    ):(
                      <Skeleton variant="rectangular" width={210} height={118} />

                    )};
                    </Box>
                <Box sx={{pr:4}} >
                  <Typography variant='h4' color="#006673" sx={{ borderBottom: 2 , borderColor: 'divider' }}>مَنَ المستفيد مِن اصول جرافيك ستوك وكيف  ؟</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> يمكنك الاستفادة منها اذا كنت : </Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> *  تعمل في مجال الدعاية والاعلان  أو الطباعة وتريد انجاز البعض من التصميمات بخبرة بسيطه في برامج التصميم .</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}>  * مصممم جرافيك مبتدئ ويمكنك التعلم منها بطريقة اسرع واكثر فاعلية .</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'600' , pt:1}}> * مصممم جرافيك محترف وتريد اختصار وقتك في تصميمات تجارية قليلة العائد يمكنك تعديلها وتوفيره الي اعمال اخري اكثر اهمية .</Typography>
                  <Typography sx={{fontSize:'lg' , fontWeight:'700' ,color:'#006673' , pt:1}}> تذكــر دائـــما ،،، انه من الغباء اعادة اختراع العجلة ، ومن الذكاء أن تبدأ من حيث انتهي الاخرون . </Typography>


                </Box>
              </Stack>

            </Stack>
    </Page>
  );
}
