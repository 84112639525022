import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../Maingallery.css';
import Page from '../../components/Page';
import Loader from '../../components/loader/Loadmore';
import Index from '../subcatpages/Index';

// material
// components
import GalleryCard from '../../components/GalleryCard';
// ----------------------------------------------------------------------
export default function PsdTemplate() {
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState (2);
  const [more, sethasMore] = useState(true);

  const [columns, setColumns] = useState(1);


  const matchesXS = useMediaQuery('(max-width:600px)');
  const matchesSM = useMediaQuery('(min-width:600px) and (max-width:960px)');
  const matchesMD = useMediaQuery('(min-width:960px) and (max-width:1280px)');
  const matchesLG = useMediaQuery('(min-width:1280px) and (max-width:1600px)');
  const matchesXL = useMediaQuery('(min-width:1600px)');
  
  


    // Check screen size and set columns accordingly
    useEffect(() => {
      if (matchesSM) {
        setColumns(2);
      } else if (matchesMD) {
        setColumns(3);
      } else if (matchesLG) {
        setColumns(3);
      } else if (matchesXL) {
        setColumns(4);
      } else {
        setColumns(1);
      }
    }, [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]);



  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  const getFiles = async () => {
    if (userInfo) {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-category/1/`, config);
      setFiles(response.data.results);
    } else {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-category/1/`);
      setFiles(response.data.results);
    }
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  

  const fetchFiles = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/files-by-category/1/?page=${page}`);
    return (res.data.results);

  };

  
  const fetchData = async () => {
    const newFiles = await fetchFiles();

    setFiles([...files, ...newFiles]);
    if (newFiles.length === 0 || newFiles.length < 25) {
      sethasMore(false);
    }
    setPage(page + 1);
  };


  return (
                <Page title="Psd Template">
                <Index categoryid={1}/>
            <InfiniteScroll
                dataLength={files.length} // This is important field to render the next data
                next={fetchData}
                hasMore={more}
                loader={<Loader />}
                endMessage={""}
                >

        <Masonry columns={columns} spacing={3.5}  sx={{pl:4 , pt:2}} >
          {files.map((file, index) => (
            <GalleryCard lovelist key={file.id} file={file} index={index} />
          ))}
        </Masonry>

            </InfiniteScroll>
            </Page>


  );
  
}


