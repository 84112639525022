import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import RelatedGallery from '../../components/RelatedGallery';
import FileDetail from '../../components/file/Filedetail';
import Download from '../../components/file/filelist/Download';
import FODetails from '../../components/file/FODetails';
import SquareAd from '../../components/ads/SquareAd'
import UserScope from '../../redux/scope/userscope';


function FileMore() {
  const { id } = useParams();
  const [file, SetFile] = useState({});
  const [loveState, setLoveState] = useState();
  const [userscopes, setUserscopes] = useState([UserScope]);
  const [reloadKey, setReloadKey] = useState(0); // Add a key state variable

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  useEffect(() => {
    const storedScopes = localStorage.getItem("userscopes");
    if (storedScopes) {
      setUserscopes(JSON.parse(storedScopes));
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/file/${id}`);
        SetFile(data);
        setUserscopes([...userscopes, file.scopes]);
        setReloadKey(prevKey => prevKey + 1); // Update the key to force reload of RelatedGallery
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [id]);

  const userLike = async () => {
    if (userInfo) {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/file/${id}`, config);
      setLoveState(response.data.user_make_like);
    } else {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/file/${id}`);
      setLoveState(response.data.user_make_like);
    }
  };

  useEffect(() => {
    userLike();
  }, [id]);

  return (
    <>
      <Page title={file.title}>
        <Stack
          direction="row"
          gap={1}
          sx={{
            height: '480px',
            width: '100%',
            backgroundColor: 'rgb(245, 245, 245',
            padding: '5px',
            flexWrap: { xs: 'wrap', md: 'wrap', lg: 'nowrap', height: '100%' },
          }}
        >
          <Box
            flex={{ sx: '0', md: '6', lg: '6' }}
            sx={{
              backgroundColor: 'rgb(243, 243, 243)',
              maxHeight: '480px',
              overflowY: 'auto',
              overflowX: 'auto',
              display: 'inline-block',
            }}
          >
            <FileDetail file={file} />
          </Box>
          <Box flex={{ md: '3', lg: '3' }}>
            <Stack direction="column" justifyContent="center">
              <Stack
                alignItems="center"
                justifyContent="center"
              >
                <SquareAd />
              </Stack>
              <Box sx={{ width: '100%' }}>
                <Download file={file} loveState={loveState} />
              </Box>
              <Box>
                <FODetails file={file} />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ marginTop: '20px', backgroundColor: 'rgb(245, 245, 245)' }}>
          <RelatedGallery  key={reloadKey} /> {/* Pass the key prop to RelatedGallery */}
        </Box>
      </Page>
    </>
  );
}

export default FileMore;
