import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@mui/material/Grid';// components
import {ImageList , Stack , Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Card25 from './Card25'



// ----------------------------------------------------------------------
export default function Index() {
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState (2);
  const [more, sethasMore] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  const getPackages = async () => {
    if (userInfo) {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/bundles/bundles-list/`, config);
      setPackages(response.data.results);

    } else {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/bundles/bundles-list/`);
      setPackages(response.data.results);
    }
  };

   
  useEffect(() => {
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const goAllSubPage = () => {
    const url =`/files/all-subcategory/`;
    window.location = url ;
  };



  return (
    <Grid container spacing={3}>
      {packages.map((packagee, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={packagee.id}>
          <Card25 packagee={packagee} index={index} />
        </Grid>
      ))}
    </Grid>
  );
  
}



