import React, { useEffect , useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import { Stack, Box, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Modal from 'react-modal';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import Iconify from '../Iconify';
import img from './offer.jpg'




const GetButton = styled('button')({
  boxShadow: '0px 5px 2px rgb(204, 204, 204)',
  border: 'none',
  textAlign: 'center',
});

const PaymentScript = ({ paymentData }) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://checkout.kashier.io/kashier-checkout.js";
    script.async = true;
    script.id = "kashier-iFrame";
    script.setAttribute('data-amount', paymentData.amount);
    script.setAttribute('data-hash', paymentData.hash);
    script.setAttribute('data-currency', paymentData.currency);
    script.setAttribute('data-orderId', paymentData.orderId);
    script.setAttribute('data-merchantId', paymentData.mid);
    script.setAttribute('data-merchantRedirect', '');
    script.setAttribute('data-mode', 'live');
    script.setAttribute('data-metaData', '{"metaData":"myData"}');
    script.setAttribute('data-description', 'ORDER-DESCRIPTION');
    script.setAttribute('data-redirectMethod', 'get');
    script.setAttribute('data-failureRedirect', 'false');
    script.setAttribute('data-allowedMethods', 'card,bank_installments,wallet');
    script.setAttribute('data-type', 'external');
    script.setAttribute('data-brandColor', '#00bcbc');
    script.setAttribute('data-display', 'en');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [paymentData]);

  return null;
};

export default function Card50() {
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showScript, setShowScript] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_KEY}/api/v1/files/deposit-kashiernew/4/`;
    const Body = {};

    axios.post(url, Body, config)
      .then(response => {
        setPaymentData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching payment data', error);
      });
  }, []);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
        config
      );
      setUserData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  
  useEffect(() => {
    getUserData();
  }, []);

  const handleClick = () => {
    setLoading(true);
    
    setTimeout(() => {
      setShowScript(true);

      setLoading(false);
    }, 2000);
  };

  return (
    <Card sx={{ maxWidth: 275 , border:1  , borderRadius:1.5  ,boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0' , mt:1 , "&:hover": {
      boxShadow:'none', background: '#fffdf3',
      cursor:'pointer',
        },}}>
      <Stack sx={{width:"100%" , alignItems:'center' , my:4 , bgcolor:'#006673'}}>
        <Stack sx={{direction:'row',alignItems:'center' , width:"70%" , borderBottom:2 , borderTop:2 , borderColor:'cyan'}}>
          <Typography display='flex' variant='h2' sx={{color:'white' , fontWeight:"600"}} >20000 <Iconify style={{ color: 'white' }} icon="ph:coins-light" className="icon" width={30} height={30} marginTop={1} /></Typography>
        </Stack>
      </Stack >
      <Stack sx={{width:"100%" , alignItems:'center' , my:2 , bgcolor:'#f1f1f1' , borderBottom: 1, borderTop: 1 , borderColor: '#006673' }}>
        <Box sx={{direction:'row'}}>
          <Typography display='flex' variant='h2' sx={{color:'green'}} >50<Typography variant='h5' > $</Typography></Typography>
        </Box>
      </Stack >
      <Box sx={{py:4 , px:2 }}>
        <Typography variant="body2" color="text.secondary">
          <DownloadDoneIcon sx={{width:20 , height:20, color:"green"}} />Pay: 50 Dollar .
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <DownloadDoneIcon sx={{width:20 , height:20, color:"green"}} />Get  : 20000 Coin .
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <DownloadDoneIcon sx={{width:20 , height:20 , color:"green"}}/>Download average : 800 file  .
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <DownloadDoneIcon sx={{width:20 , height:20 , color:"green"}} />Coins EXP : Valid for a life .
        </Typography>
      </Box >
      <Stack sx={{width:"100%" , alignItems:'center' , mb:5 , bgcolor:'#f8f9fa'}}>
        {loading ? (
          <CircularProgress sx={{width:'1rem', height:'1rem', color:'#006673'}}/>
        ) : (
          <GetButton sx={{width:170  , bgcolor:'#006673' , borderRadius:.7}} onClick={handleClick}>
            <Typography sx={{py:1, color:'cyan' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >Charge Now</Typography>
          </GetButton>
         
        )}
         {showScript && <PaymentScript paymentData={paymentData}/>}
      </Stack>

    </Card>
  );
}
