import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@mui/material/Grid';// components
import {ImageList , Stack , Typography} from '@mui/material';
import Box from '@mui/material/Box';

import Page from '../../components/Page';
import GalleryCard from '../../components/GalleryCard';
import Onesub from './Onesub'; 


// ----------------------------------------------------------------------
export default function Index({categoryid}) {
  const [subcat, setSubcat] = useState([]);
  const [page, setPage] = useState (2);
  const [more, sethasMore] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };
  // -----*-----*------*------*-----*-----*-----*-----*-----*-----
  const getSubcat = async () => {
    if (userInfo) {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/catsubcategorys/${categoryid}`, config );
      setSubcat(response.data.results);

    } else {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/v1/files/catsubcategorys/${categoryid}`);
      setSubcat(response.data.results);
    }
  };


  
  useEffect(() => {
    getSubcat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const goAllSubPage = () => {
    const url =`/files/all-subcategory/`;
    window.location = url ;
  };


  return (
    <Box sx={{mb:5 , mx:0 ,  boxShadow:'rgba(0, 0, 0, .15) 0 3px 9px 0'}}>
      <Stack direction="row" spacing={1.5} sx={{width:'100%', display:'flex', overflowX:'scroll' ,backgroundColor:'wight', p:2 }} >
      {subcat.map((subcat, index) => (

        <Onesub key={subcat.id} subcat={subcat} index={index} />
        
        ))}
              <Box onClick={goAllSubPage}  sx={{ textAlign: 'center', alignItems: 'center', minHeight:'200px' , minWidth:'200px', border:'.5px solid #d0d0d0', padding:'0px' , borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0' ,
          "&:hover": {
         boxShadow:'none', background: '#f3f3f3',
         cursor:'pointer',
           },}} > 
         <Typography sx={{fontSize:'1.3rem' , fontWeight:'600' , pt:6 , color:'green'}}> See All <br/>Subcategorys </Typography>
         <Typography sx={{fontSize:'4rem' , fontWeight:'800', color:'green'}} > + </Typography>
         </Box>
      </Stack>
      </Box>
   
  );
}



