import React, { useEffect , useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import { Stack, Box, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import Iconify from '../Iconify';
import img from './offer.jpg'
import ChargeConfirmationModal from '../Form/ChargeConfirmationModal'





const GetButton = styled('button')({
  boxShadow: '0px 3px 2px rgb(204, 204, 204)',
  border: 'none',
  textAlign: 'center',
  backgroundColor: 'rgb(0, 125, 125)', // Original color
  '&:hover': {
    backgroundColor: 'rgb(0, 100, 100)', // Darker color on hover
  },
});



export default function Card25(packagee , index) {
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showccm, setShowCcm] = useState(false);

  const handleClose = () => setShowCcm(false);

 // console.log(packagee);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo?.access}`,
    },
  };

  //  getting sub_User_Data Function
  const getUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/v1/accounts/user-details/${userInfo?.id}`,
        config
      );
      setUserData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   <Stack component="img" src={img} sx={{  maxWidth:'245px'}} /> 




  return (
    <Card sx={{ maxWidth: 255 , minWidth:250, border:1, alignItems:'center'    , borderRadius:1.5  ,boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0' , mt:1 , "&:hover": {
      boxShadow:'none', background: '#fffdf3',
      cursor:'pointer',
        },}}>
                 

      <Stack sx={{width:"100%" , alignItems:'center' , my:4 , bgcolor:'rgb(0, 125, 125)'}}>
      <Stack sx={{direction:'row',alignItems:'center' , width:"70%" , borderBottom:2 , borderTop:2 , borderColor:'cyan'}}>
      <Typography display='flex' variant='h2' sx={{color:'white' , fontWeight:"600"}} >{packagee.packagee.coins} <Iconify style={{ color: 'white' }} icon="ph:coins-light" className="icon" width={30} height={30} marginTop={1} /></Typography>

      </Stack>
      </Stack >
      <Stack sx={{width:"100%" , alignItems:'center' , my:2 , bgcolor:'#f1f1f1' , borderBottom: 1, borderTop: 1 , borderColor: '#006673' }}>
        <Box sx={{direction:'row'}}>
        <Box display='flex' sx={{color:'rgb(0, 125, 125)'}}>
          <Typography variant='h2'>{packagee.packagee.price}</Typography>
          <Typography variant='h5'>$</Typography>
        </Box>
      </Box>
      </Stack >
 
      <Box sx={{py:4 , px:2 }}>
      <Typography variant="body2" color="text.secondary">
         <DownloadDoneIcon sx={{width:20 , height:20, color:"rgb(0, 125, 125)"}} />Pay only: {packagee.packagee.price} Dollar .
        </Typography>
        <Typography variant="body2" color="text.secondary">
         <DownloadDoneIcon sx={{width:20 , height:20, color:"rgb(0, 125, 125)"}} />Get  : {packagee.packagee.coins} Coin .
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <DownloadDoneIcon sx={{width:20 , height:20, color:"rgb(0, 125, 125)"}}/>Download average :{packagee.packagee.coins / 20} file .
        </Typography>
        <Typography variant="body2" color="text.secondary">
        <DownloadDoneIcon sx={{width:20 , height:20 , color:"rgb(0, 125, 125)"}} />Coins EXP : Valid for a life .
        </Typography>
      </Box >
      <Stack sx={{width:"100%" , alignItems:'center' , mb:5 , bgcolor:'#f8f9fa'}}>
      {loading ? (
                  <CircularProgress sx={{width:'1rem', height:'1rem', color:'rgb(0, 125, 125)'}}/>
              ) : (
      <GetButton sx={{width:170  , bgcolor:'rgb(0, 125, 125)' , borderRadius:.7}} onClick={() => setShowCcm(true)}>
        <Typography sx={{py:1, color:'white' , borderBottom:1, borderTop:1 , borderColor:'cyan'  }} >Charge Now</Typography>
        </GetButton>
         )}
          {showccm && <ChargeConfirmationModal
          packagee = {packagee.packagee}
          userData = {userData}
          show={showccm}
          handleClose={handleClose} />}

      </Stack>
    
    </Card>
  );
}