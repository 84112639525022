export const states = [
    {
      value: 'Alexandria' ,
      label: 'Alexandria' ,
    },
    {
      value: 'Aswan' ,
      label: 'Aswan' ,
    },
    {
      value: 'Asyut' ,
      label: 'Asyut' ,
    },
    {
      value: 'Beheira' ,
      label: 'Beheira' ,
    },
    {
      value: 'Beni Suef ',
      label: 'Beni Suef ',
    },
    {
      value: 'Cairo' ,
      label: 'Cairo' ,
    },
    {
      value: 'Dakahlia' ,
      label: 'Dakahlia' ,
    },
    {
      value: 'Damietta' ,
      label: 'Damietta' ,
    },
    {
      value: 'Faiyum' ,
      label: 'Faiyum' ,
    },
    {
      value: 'Gharbia'  ,
      label: 'Gharbia'  ,
    },
    {
      value: 'Giza'  ,
      label: 'Giza'  ,
    },
    {
      value: 'Ismailia'  ,
      label: 'Ismailia'  ,
    },
    {
      value: 'Kafr El Sheikh ' ,
      label: 'Kafr El Sheikh ' ,
    },
    {
      value: 'Luxor ' ,
      label: 'Luxor ' ,
    },
    {
      value: 'Matruh'  ,
      label: 'Matruh'  ,
    },
    {
      value: 'Minya'  ,
      label: 'Minya'  ,
    },
    {
      value: 'Monufia'  ,
      label: 'Monufia'  ,
    },
    {
      value: 'New Valley ' ,
      label: 'New Valley ' ,
    },
    {
      value: 'North Sinai ' ,
      label: 'North Sinai ' ,
    },
    {
      value: 'Port Said' ,
      label: 'Port Said' ,
    },
    {
      value: 'Qalyubia ' ,
      label: 'Qalyubia ' ,
    },
    {
      value: 'Qena ' ,
      label: 'Qena ' ,
    },
    {
      value: 'Red Sea ' ,
      label: 'Red Sea ' ,
    },
    {
      value: 'Sharqia'  ,
      label: 'Sharqia'  ,
    },
    {
      value: 'Sohag'  ,
      label: 'Sohag'  ,
    },
    {
      value: 'South Sinai ' ,
      label: 'South Sinai ' ,
    },
    {
      value: 'Suez'  ,
      label: 'Suez'  ,
    },
  ];