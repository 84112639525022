import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {  Stack } from '@mui/material';


import img7 from './wp/ai_wall_paper_design_7.jpg'
import img1 from './wp/wall_paper_-_osoul_2021_90.jpeg'
import img2 from './wp/wall_paper_-_osoul_2021_100.jpeg'
import img3 from './wp/wall_paper_-_osoul_2021_113.jpeg'
import img4 from './wp/wall_paper_-_osoul_2021_149.jpeg'
import img5 from './wp/wall_paper_-_osoul_2021_164.jpeg'


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'اوراق حائط حديثة',
    imgPath: img1,
  },
  {
    label: 'اوراق حائط تجارية',
    imgPath: img2,
  },
  {
    label: 'اوراق حائط كلاسيكية',
    imgPath: img4,
  },
  {
    label: 'والعديد من التصميمات',
    imgPath: img1,
  },
  {
    label: 'مناظر طبيعية',
    imgPath: img5,
  },
  {
    label: 'تصميمات فيكتور',
    imgPath: img7,
  },
 
 
];

function Db() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} spacing={5}>
        <Box sx={{ px:1 , minWidth:'45%' }}>
            <Box sx={{backgroundColor: '#006673' , p:1 }}>
            <Typography variant='h5' color='white'> مكتبة تصميمات ورق الحائط </Typography>
            </Box>
            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1 }}> لاينبغي ان تهمل الجزء الخاص بتصميم وطباعة وتنفيذ أوراق الجدران إذا كنت تعمل في مجال الدعاية والاعلان - بل يجب ان تعتبرها من صميم عملك فأنت صاحب الذوق في تناسق الالوان وصاحب خبرة في مجال الطباعة علي الخامات المختلفة وصاحب خبرة ايضا في تركيب ولصق المطبوعات الكبيرة ،، إذا لاينبغي ان تهمل انت اضافتها الي عملك والاعلان عنها كخدمة تستطيع تقديمها،، ولا ينبغي ان نهمل نحن ايضا ايجاد مكتبة او كتالوج تصميمات متنوع يدعم هدفك ،، </Typography>

            <Typography sx={{fontSize:'lg' , fontWeight:'600' , p:1}}>  لذلك قدمنا لكم مجموعة ضخمة من تصميمات اوراق الجدران الحديثة والكلاسيكية والقابلة للطباعة علي مقاسات كبيرة تكفي لجدار كامل ، وعلي وعد بتقديم المزيد والمزيد بإذن الله </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600', p:1 }}>  ايضا العديد والعديد من تصميمات ورق الحائط التجارية والمناسبة للشركات والمكاتب والمحلات التجارية      </Typography>

         </Box>
         <Box sx={{ px:5}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='h6'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Stack>
  );
}

export default Db;