import React, { useState } from 'react';
import { Box ,Typography } from '@mui/material';
import '../Maingallery.css';
import SubCatCard from './SubCatCard';

// material
// components
// ----------------------------------------------------------------------
export default function Onesub({subcat, index}) {
  const [subcattitle , setSubcattitle] = useState([subcat.title]);
  const [subid , setSubid] = useState([subcat.id]);

  
  
  
  const goSubcat = () => {
    const url =`/files/subcategory/${subid}/${subcattitle}/`;
    window.location = url ;
  };

  return (
        <Box onClick={goSubcat}  sx={{  border:'.5px solid #d0d0d0', padding:'0px' , borderRadius:.5 ,backgroundColor:'white',  boxShadow:'rgba(0, 0, 0, .15 ) 0 3px 9px 0' ,
          "&:hover": {
         boxShadow:'none', background: '#f3f3f3',
         cursor:'pointer',
          
        },}} > 
                  <>
            <SubCatCard key={subcat.id} subcat={subcat}  index={index} />
            <Typography sx={{px:1,   whiteSpace: 'nowrap' , 
        overflow: 'hidden',
        fontWeight:'600',
        textoverflow: 'ellipsis',
        width: '11.5rem' ,
        "&:hover": {
          
          fontWeight:'800',
           
         } }}> {subcattitle}</Typography>  </>       


        </Box>

       

  );
}

const item = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
      featured: true,
    },
 
]