import { Grid, Paper ,Container, Stack, TextField , Typography, Card, CardContent, Box, CardActions, Link } from '@mui/material';

// components
// import Page from '../components/Page';
import React, { useEffect, useState } from 'react';
// bootsrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';




import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import UploadImage from '../../components/Form/UploadImage';
import Page from '../../components/Page';
import img from './bk.jpg'
// ----------------------------------------------------------------------

export default function PsIndex() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userFullName = userInfo.name;
  const userFirstName = userFullName.split(' ');

  // Data after sending the token Validation__Authorization
  const [userData, setUserData] = useState({});
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.access}`,
    },
  };
   const [temp , setTemp] = useState();
   const [info , setInfo] = useState({
    tempname:'سليم محمد سليمان',
    temptxt1:'إنه من دواعي سرورنا التوجة بكل الحب والتقدير والامتنان' ,
    temptxt2:'الي الطفل',
    temptxt3:'وذلك لإتمامه صيام شهر رمضان المبارك لعام 2023 '

   })

   const styles = {
    paperContainer: {

      display: 'flex',
      backgroundImage: `url(${temp})`,
      backgroundSize: 'cover',
      width:'1480px',

      overflowX:'scroll'

    }
};

 const handleImg = () =>{
  setTemp(img);
 };








  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 5 }}>
          Hi, Welcome {userFirstName[0]} 👋
          <br />
        </Typography>

        <Stack direction="row" spacing={1.5} sx={{width:'100%',maxHeight:300 ,display:'flex', overflowX:'scroll' , backgroundColor:'wight', py:1 , mb:4}} >
        <Box component="img" src={img} sx={{width:400 , cursor:'pointer'}} onClick={handleImg} />
        <Box component="img" src={img} sx={{width:400 , cursor:'pointer'}} onClick={handleImg} />
        <Box component="img" src={img} sx={{width:400 , cursor:'pointer'}} onClick={handleImg} />
        <Box component="img" src={img} sx={{width:400 , cursor:'pointer'}} onClick={handleImg} />
        <Box component="img" src={img} sx={{width:400 , cursor:'pointer'}} onClick={handleImg} />


        </Stack>

     
              <Paper square sx={{height:'1055px'}} style={styles.paperContainer} > 
              <Box component="img" src={img} sx={{ ml:12.5 , mt:23.3, borderRadius:50, width:260 ,height:260, cursor:'pointer'}} onClick={handleImg} />
               <Stack sx={{ml : 10 , mt:40 , width:'60%' , height:450 , border:'0px solid red' , direction:'rtl' , alignItems:'center'}}>

               <Stack sx={{alignItems:'center'}}>     
                <Typography  sx={{fontSize:"2.5rem" , color:'yellow'}} >{info.temptxt1}</Typography>
                <Typography  sx={{fontSize:"3rem" , color:'yellow'}}>{info.temptxt2}</Typography>

              </Stack>
              <Box >     
                <Typography  sx={{fontSize:"8rem" , color:'#fff'}} >{info.tempname}</Typography>
              </Box>

              <Typography  sx={{fontSize:"2.5rem" , color:'yellow'}}>{info.temptxt3}</Typography>

              </Stack>
              </Paper>
           
           



         


      </Container>
      

              <Stack padding="20px">
              <Button >
                Export pdf
              </Button>
              </Stack>
    </Page>
  );
}
