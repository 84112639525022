import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {  Stack } from '@mui/material';

import img1 from './db/bannar_background.jpeg';
import img2 from './db/bannar_mock_up.jpg';
import img3 from './db/marble_background_2.jpeg';
import img4 from './db/meat__chiken.jpeg';
import img5 from './db/Pharaonic_vector_drawings.jpeg';
import img6 from './db/vector_chef.jpeg';
import img7 from './db/Islamic_manuscripts.jpeg';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'خلفيات نادرة',
    imgPath: img1 ,
  },
  {
    label: 'موك اب',
    imgPath: img2 ,
  },
  {
    label: 'خلفيات رخامية',
    imgPath: img3 ,
  },
  {
    label: 'صور بجودة عالية',
    imgPath: img4 ,
  },
  {
    label: 'رسومات فيكتور',
    imgPath: img5 ,
  },
  {
    label: 'رسومات فيكـتور',
    imgPath: img6 ,
  },
  {
    label: 'مخطوطات اسلامية',
    imgPath: img7 ,
  },
 
];

function Db() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'column', lg:'row' , md:'row' }} spacing={5}>
        <Box sx={{ px:1 , minWidth:'45%' }}>
            <Box sx={{backgroundColor: '#006673' , p:1 }}>
            <Typography variant='h5' color='white'> مكتبة المصمم المحترف </Typography>
            </Box>
            <Typography sx={{fontSize:'lg' , fontWeight:'600', p:1 }}>  مجموعة رائعة ومتخصصة من الملحقات والادوات والبرامج والخطوط الموجهة للعاملين بمجال التصميم التجاري او الدعاية والاعلان    </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600', p:1 }}>  مكتبة كبيرة من الصورة الصالحة للطباعة علي مقاسات كبيرة تناسب مطبوعات الاوت دور ، فربما اردت تغير الصورة الموجودة في احد تصميماتنا او اردت الاستعانة بها في عملك     </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600', p:1 }}>  مكتبة ملفات الفيكتور : وهي مكتبة متنوعة مابين خلفيات التصميمات وبالاخص اللوحات الاعلانية وبعض الشعارات والمخطوطات الاسلامية والرسومات الفرعونية والماندلا التي لابد وان تحتاج الي بعضها ان لم يكن اكثرها في عملك     </Typography>
            <Typography sx={{fontSize:'lg' , fontWeight:'600', p:1 }}>  مكتبة ملحقات وادوات وبرامج التصميم : وفيها نقدر لك مانستخدمة من خطوط واشكال وتدرجات لونيه او حتي فلاتر وملحقات او بعض البرامج التي نستخدمها في عملنا     </Typography>

         </Box>
         <Box sx={{ px:5}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          bgcolor: 'background.default',
        }}
      >
        <Typography variant='h6'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
    </Stack>
  );
}

export default Db;